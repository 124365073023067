import { LogLevel } from '@azure/msal-browser';


const isIE =
    window.navigator.userAgent.indexOf("MSIE ") > -1 ||
    window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signIn: "B2C_1A_V1_LINDE_PORTAL_SIGNIN",
        signUpEmail: "B2C_1A_V1_LINDE_PORTAL_SIGNUP_EMAIL",
        signUpPhone: "B2C_1A_V1_LINDE_PORTAL_SIGNUP_PHONE",
        userChangePhoneNumber: "B2C_1A_V1_LINDE_PORTAL_USER_CHANGE_PHONENUMBER",
        userChangeSignInEmail: "B2C_1A_V1_LINDE_PORTAL_USER_CHANGE_SIGNINEMAIL",
        userPasswordReset: "B2C_1A_V1_LINDE_PORTAL_USER_PASSWORDRESET",
        lindePortalSignInAAD: "B2C_1A_V1_Linde_Portal_SignIn_AAD"
    },
    authorities: {
        signIn: {
            authority: "https://b2c.linde.com/lindeb2cprd.onmicrosoft.com/B2C_1A_V1_LINDE_PORTAL_SIGNIN",
        },
        signUpEmail: {
            authority: "https://b2c.linde.com/lindeb2cprd.onmicrosoft.com/B2C_1A_V1_LINDE_PORTAL_SIGNUP_EMAIL",
        },
        signUpPhone: {
            authority: "https://b2c.linde.com/lindeb2cprd.onmicrosoft.com/B2C_1A_V1_LINDE_PORTAL_SIGNUP_PHONE",
        },
        userChangePhoneNumber: {
            authority: "https://b2c.linde.com/lindeb2cprd.onmicrosoft.com/B2C_1A_V1_LINDE_PORTAL_USER_CHANGE_PHONENUMBER",
        },
        userChangeSignInEmail: {
            authority: "https://b2c.linde.com/lindeb2cprd.onmicrosoft.com/B2C_1A_V1_LINDE_PORTAL_USER_CHANGE_SIGNINEMAIL",
        },
        userPasswordReset: {
            authority: "https://b2c.linde.com/lindeb2cprd.onmicrosoft.com/B2C_1A_V1_LINDE_PORTAL_USER_PASSWORDRESET"
        },
        lindePortalSignInAAD: {
            authority: "https://b2c.linde.com/lindeb2cprd.onmicrosoft.com/B2C_1A_V1_Linde_Portal_SignIn_AAD"
        }
    },
    authorityDomain: "b2c.linde.com"
}

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfigB2C = {
    auth: {
        clientId: "13e7aa4c-488a-4978-8dd2-db6aa3a87428", // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signIn.authority, // Use a sign-up/sign-in user-flow as a default authority
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: "/", // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: string, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        if (process.env.NODE_ENV === 'development') {
                            console.error(message);
                        }
                        return;
                    case LogLevel.Info:
                        if (process.env.NODE_ENV === 'development') {
                            console.info(message);
                        }
                        return;
                    case LogLevel.Verbose:
                        if (process.env.NODE_ENV === 'development') {
                            console.debug(message);
                        }
                        return;
                    case LogLevel.Warning:
                        if (process.env.NODE_ENV === 'development') {
                            console.warn(message);
                        }
                        return;
                }
            }
        }
    }
};

export const msalConfigB2CPosition = {
    auth: {
        clientId: "2df6ebc0-720f-412c-8ae5-c629725763cd", // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.lindePortalSignInAAD.authority, // Use a sign-up/sign-in user-flow as a default authority
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: "/elevate/admin/position", // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: string, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        if (process.env.NODE_ENV === 'development') {
                            console.error(message);
                        }
                        return;
                    case LogLevel.Info:
                        if (process.env.NODE_ENV === 'development') {
                            console.info(message);
                        }
                        return;
                    case LogLevel.Verbose:
                        if (process.env.NODE_ENV === 'development') {
                            console.debug(message);
                        }
                        return;
                    case LogLevel.Warning:
                        if (process.env.NODE_ENV === 'development') {
                            console.warn(message);
                        }
                        return;
                }
            }
        }
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: []
};

export const azureAADLoginRequest = {
    authority: b2cPolicies.authorities.lindePortalSignInAAD,
    scopes: ['offline_access', 'openid', 'profile']
}

export const tokenRequest = {
    scopes: ["openid", "profile"],  // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
    forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net"
};