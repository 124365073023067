import React, { useContext, useEffect, useState, useRef } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import BusinessIcon from "@mui/icons-material/Business";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import StoreIcon from "@mui/icons-material/Store";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import HistoryIcon from "@mui/icons-material/History";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../configs/authConfig";
import { UserContext } from "../../context";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { api } from "../../services";
import { generateToken } from "../../services/fetch-api/auth/authToken";
import { getLocaleKey } from "../../helpers";
import CircularProgress from "@mui/material/CircularProgress";
import { Avatar, Grid, LinearProgress, Skeleton, Stack } from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { toast } from "react-toastify";
import { IChosenBS } from "../../types";
import Position from "../../pages/positions";

interface ILayout {
    children: React.ReactNode;
}

const drawerWidth = 300;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const Layout = (props: ILayout) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { state, dispatch } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const { children } = props;
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [groups, setGroups] = useState([]);
    const [lastApp, setLastApp] = useState(0);
    const [lastAppLoading, setLastAppsLoading] = useState(false);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [chosenBS, setChosenBS] = useState<IChosenBS>();
    const [appOptions, setAppOptions] = useState<any>([]);
    const [selectedRegion, setSelectedRegion] = useState<any>();
    const [countries, setCountries] = useState<any>();


    useEffect(() => {
        setLastAppsLoading(true);
        state.user &&
            state.user?.firstName &&
            state.user?.lastName &&
            api.userDetails
                .getUsersLastApp(state.user, generateToken(instance, accounts))
                ?.then((res: any) => {
                    if (!res) return;
                    setLastApp(res.id);
                    setLastAppsLoading(false);
                });
    }, [state.user]);


    useEffect(() => {
        state.user &&
            state.user?.firstName &&
            state.user?.lastName &&
            setGroups(state.user.groups);
        state.appId !== null &&
            api.userDetails
                .getSupportedLanguages(state.appId)
                .then((res: any) => {
                    if (!res) return;
                    setLanguageOptions(res);
                });
    }, [state.user, state.appId]);

    useEffect(() => {
        if (lastApp === 1) {
            dispatch({
                type: "SET_APPID",
                data: 1,
            });
        } else if (lastApp === 3) {
            dispatch({
                type: "SET_APPID",
                data: 3,
            });
        } else if (lastApp === Number(process.env.REACT_APP_LINX_ID)) {
            dispatch({
                type: "SET_APPID",
                data: Number(process.env.REACT_APP_LINX_ID),
            });
        } else if (lastApp === -1) {
            if (state.user.role === undefined || state.user.role === "ExternalUser") {
                dispatch({
                    type: "SET_APPID",
                    data: Number(process.env.REACT_APP_LINX_ID),
                });
            } else {
                dispatch({
                    type: "SET_APPID",
                    data: 1,
                });
            }
        }


        lastApp !== 0 &&
            state.user &&
            state.user?.firstName &&
            state.user?.lastName &&
            api.userDetails.setUsersLastApp(
                state.user,
                generateToken(instance, accounts),
                lastApp
            );
    }, [lastApp]);

    const handleAppName = (data: number) => {
        if (data === 1) {
            return "Elevate";
        } else if (data === 3) {
            return "LiDAP";
        } else if (data === Number(process.env.REACT_APP_LINX_ID)) {
            return "LINX";
        } else {
            return appOptions.length > 0 && appOptions[0].label;
        }
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleChange = (value: any) => {
        if (value.value === 1 || value.value === 3) {
            setLastApp(value.value);
            dispatch({
                type: "SET_CHOSEN_LINX_COUNTRY_OBJECT",
                data: null,
            });
        } else if (value.value === Number(process.env.REACT_APP_LINX_ID)) {
            setLastApp(Number(process.env.REACT_APP_LINX_ID));
        } else {
            return;
        }
    };

    const customStyles = {
        control: () => ({
            display: "flex",
            width: 315,
            backgroundColor: "white",
            color: "black",
            marginRight: 20,
        }),
        option: (provided: any) => ({
            ...provided,
            color: "black",
        }),
        menu: (provided: any) => ({
            ...provided,
            width: 300,
            left: 0,
        }),
    };

    const customStylesLinx = {
        control: () => ({
            display: "flex",
            width: 215,
            backgroundColor: "white",
            color: "black",
            marginRight: 20,
        }),
        option: (provided: any) => ({
            ...provided,
            color: "black",
        }),
        menu: (provided: any) => ({
            ...provided,
            width: 200,
            left: 0,
        }),
        placeholder: (provided: any) => ({
            ...provided,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        }),
    };

    const customLanguageStyles = {
        control: () => ({
            display: "flex",
            width: 150,
            backgroundColor: "white",
            color: "black",
        }),
        option: (provided: any) => ({
            ...provided,
            color: "black",
        }),
        menu: (provided: any) => ({
            ...provided,
            width: 150,
            right: 0,
        }),
        menuList: (provided: any) => ({
            ...provided,

            "::-webkit-scrollbar": {
                width: "4px",
                height: "0px",
            },
            "::-webkit-scrollbar-track": {
                background: "#f1f1f1",
            },
            "::-webkit-scrollbar-thumb": {
                background: "#888",
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555",
            },
        }),
    };

    useEffect(() => {
        state.user &&
            api.entitlement
                .getAppInfoByAdminGroupPost(
                    state.user,
                    generateToken(instance, accounts),
                    state.user.groups
                )
                .then((data: any) => {
                    setAppOptions(
                        data.map((data: any) => {
                            return { value: data.id, label: data.name };
                        })
                    );
                });
    }, [state.appId, state.user]);

    const handleLanguageChange = (value: any) => {
        dispatch({
            type: "SET_LANGUAGE",
            data: value.value,
        });
        localStorage.setItem("i18nextLng", value.value);

        window.location.reload();
    };

    /*TRANSLATE REGION AND COUNTRY LABELS ON LANGUAGE CHANGE*/
    useEffect(() => {
        state.linxCountries.map((country: any) => {
            if (localStorage.getItem("linxCountry")) {
                if (
                    JSON.parse(localStorage.getItem("linxCountry") || "")
                        .value === country[0].id
                ) {
                    localStorage.removeItem("linxCountry");
                    localStorage.setItem(
                        "linxCountry",
                        JSON.stringify({
                            value: country[0].id,
                            label: country[0].name,
                        })
                    );
                }
            }
        });

        state.linxRegionsAndCountries &&
            state.linxRegionsAndCountries.map((region: any) => {
                if (localStorage.getItem("linxRegion")) {
                    if (
                        JSON.parse(localStorage.getItem("linxRegion") || "")
                            .id === region.id
                    ) {
                        localStorage.removeItem("linxRegion");
                        localStorage.setItem(
                            "linxRegion",
                            JSON.stringify(region)
                        );
                    }
                }
            });
    }, [state.linxCountries, state.linxRegionsAndCountries]);

    useEffect(() => {
        state.user &&
            state.user?.firstName &&
            state.user?.lastName &&
            state.chosenBusinessSegment?.internalId !== undefined &&
            state.appId !== 3 &&
            api.userDetails
                .setUsersLastBusinessSegment(
                    state.user,
                    generateToken(instance, accounts),
                    state.chosenBusinessSegment?.internalId
                )
                ?.then((res) => {
                    dispatch({
                        type: "SET_APPID",
                        data: 1,
                    });
                    api.userDetails.setUsersLastApp(
                        state.user,
                        generateToken(instance, accounts),
                        1
                    );
                });
        if (chosenBS !== undefined) {
            dispatch({
                type: "SET_CHOSEN_BUSINESS_SEGMENT",
                data: chosenBS,
            });
        }
    }, [chosenBS]);

    const bsOptions =
        state.businessSegment !== undefined &&
        state.businessSegment.businessSegments.map((data: any) => {
            return {
                value: data.internalId,
                label: data.externalId + " - " + data.name,
            };
        });

    const handleChangeBS = (value: any) => {
        state.businessSegment && state.businessSegment.businessSegments.forEach((bs: any) => {
            if (bs.internalId === value.value) {
                setChosenBS(bs);
                dispatch({
                    type: "SET_CHOSEN_BUSINESS_SEGMENT",
                    data: bs,
                });
            }
        });
        const message = t("successMessage.businessSegmentChanged");
        toast.success(message);
    };

    const handleChangeLinxCountry = (value: any) => {
        dispatch({
            type: "SET_CHOSEN_LINX_COUNTRY",
            data: value,
        });

        localStorage.setItem("linxCountry", JSON.stringify(value));
        if (state.chosenLinxCountry) {
            if (state.chosenLinxCountry.value !== value.value) {
                const message = t("label.countryChanged");
                toast.success(message);
            }
        }
    };

    useEffect(() => {
        if (state.chosenLinxCountry) {
            [].concat(...state.linxCountries).map((country: any) => {
                if (
                    state.appId === Number(process.env.REACT_APP_LINX_ID) &&
                    country.id === state.chosenLinxCountry.value
                ) {
                    dispatch({
                        type: "SET_CHOSEN_LINX_COUNTRY_OBJECT",
                        data: country,
                    });
                }
            });
        }
    }, [state.chosenLinxCountry]);

    useEffect(() => {
        if (localStorage.getItem("linxRegion") !== null) {
            setSelectedRegion(
                JSON.parse(localStorage.getItem("linxRegion") || "")
            );
        } else {
            state.linxRegionsAndCountries &&
                setSelectedRegion(state.linxRegionsAndCountries[0]);
        }
    }, [state.linxRegionsAndCountries]);

    let regionsPlaceholder = "";

    if (
        state.appId === Number(process.env.REACT_APP_LINX_ID) &&
        state.linxCountries.length > 0
    ) {
        if (localStorage.getItem("linxRegion") !== null) {
            regionsPlaceholder = JSON.parse(
                localStorage.getItem("linxRegion") || ""
            )?.code;
        } else {
            regionsPlaceholder =
                state.linxRegionsAndCountries &&
                state.linxRegionsAndCountries[0].code;
        }
    }

    let countryPlaceholder = "";

    if (
        state.appId === Number(process.env.REACT_APP_LINX_ID) &&
        state.linxCountries.length > 0
    ) {
        if (localStorage.getItem("linxCountry") !== null) {
            countryPlaceholder = JSON.parse(
                localStorage.getItem("linxCountry") || ""
            )?.label;
        } else {
            countryPlaceholder = state.chosenLinxCountry
                ? state.chosenLinxCountry.label
                : state.linxCountries[0][0].name;
        }
    }

    const handleChangeLinxRegion = (event: any) => {
        state.linxRegionsAndCountries.map((data: any) => {
            if (event.value === data.id) {
                setSelectedRegion(data);
                localStorage.setItem("linxRegion", JSON.stringify(data));
                localStorage.setItem(
                    "linxCountry",
                    JSON.stringify({
                        value: data.linxBusinesses[0].id,
                        label: data.linxBusinesses[0].name,
                    })
                );

                dispatch({
                    type: "SET_CHOSEN_LINX_COUNTRY",
                    data: {
                        value: data.linxBusinesses[0].id,
                        label: data.linxBusinesses[0].name,
                    },
                });
            }
        });
    };

    useEffect(() => {
        if (selectedRegion !== undefined) {
            setCountries(
                selectedRegion.linxBusinesses.map((country: any) => {
                    return { value: country.id, label: country.name };
                })
            );
        }
    }, [selectedRegion]);

    console.log('STATE', state);

    return (
        <>
            {window.location.pathname !== "/elevate/admin/position" ? (
                (state.appId === Number(process.env.REACT_APP_LINX_ID)) ||
                    state.appId === 1 ||
                    state.appId === 3 ||
                    !state.user ||
                    !state.user.firstName ? (
                    <Box sx={{ display: "flex" }}>
                        <CssBaseline />
                        <AppBar position="fixed" open={open}>
                            <Toolbar
                                style={{
                                    backgroundColor:
                                        state.chosenLinxCountryObject
                                            ? state.chosenLinxCountryObject
                                                .themeColour
                                            : "#005591",
                                }}
                            >
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    sx={{
                                        marginRight: 5,
                                        ...(open && { display: "none" }),
                                    }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <div></div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {state.appId ===
                                            Number(
                                                process.env.REACT_APP_LINX_ID
                                            ) &&
                                            state.linxRegions.length > 0 &&
                                            selectedRegion && (
                                                <Select
                                                    options={state.linxRegions}
                                                    placeholder={
                                                        regionsPlaceholder
                                                    }
                                                    styles={customStylesLinx}
                                                    onChange={
                                                        handleChangeLinxRegion
                                                    }
                                                />
                                            )}
                                        {state.appId ===
                                            Number(
                                                process.env.REACT_APP_LINX_ID
                                            ) &&
                                            countries && state.chosenLinxCountry && state.linxCountries.length > 0 && (
                                                <Select
                                                    options={countries}
                                                    onChange={
                                                        handleChangeLinxCountry
                                                    }
                                                    placeholder={
                                                        countryPlaceholder
                                                    }
                                                    styles={customStylesLinx}
                                                    value={countries && countries.filter(
                                                        (country: any) =>
                                                            country.value ===
                                                            state
                                                                .chosenLinxCountry
                                                                .value
                                                    )}
                                                />
                                            )}

                                        {state.businessSegment === undefined &&
                                            state.appId !== 3 ? (
                                            state.appId &&
                                            state.chosenBusinessSegment &&
                                            state.appId === 1 &&
                                            appOptions.length > 0 && (
                                                <Select
                                                    styles={customStyles}
                                                    onChange={handleChangeBS}
                                                    placeholder={t(
                                                        "loadingBusinessSegment"
                                                    )}
                                                />
                                            )
                                        ) : appOptions.length > 1 &&
                                            state.appId &&
                                            state.appId === 1 ? (
                                            <Select
                                                styles={customStyles}
                                                options={bsOptions}
                                                onChange={handleChangeBS}
                                                placeholder={
                                                    state.chosenBusinessSegment
                                                        ?.externalId +
                                                    " - " +
                                                    state.chosenBusinessSegment
                                                        ?.name
                                                }
                                            />
                                        ) : appOptions.length === 1 &&
                                            state.appId &&
                                            state.appId === 1 ? (
                                            state.appId &&
                                            state.appId === 1 && (
                                                <Select
                                                    styles={customStyles}
                                                    options={bsOptions}
                                                    onChange={handleChangeBS}
                                                    placeholder={
                                                        state
                                                            .chosenBusinessSegment
                                                            ?.externalId +
                                                        " - " +
                                                        state
                                                            .chosenBusinessSegment
                                                            ?.name
                                                    }
                                                />
                                            )
                                        ) : (
                                            state.appId &&
                                            state.appId === 1 && (
                                                <Select
                                                    styles={customStyles}
                                                    isDisabled
                                                    options={bsOptions}
                                                    placeholder={
                                                        state
                                                            .chosenBusinessSegment
                                                            ?.externalId +
                                                        " - " +
                                                        state
                                                            .chosenBusinessSegment
                                                            ?.name
                                                    }
                                                />
                                            )
                                        )}

                                        {appOptions &&
                                            appOptions.length > 0 &&
                                            state.user &&
                                            state.user?.firstName &&
                                            (state.user.role === "Administrator" ||
                                                state.user.role ===
                                                "SuperAdministrator" ||
                                                state.user.role === undefined) ? (
                                            <Select
                                                options={appOptions}
                                                styles={customStyles}
                                                onChange={(value: any) =>
                                                    handleChange(value)
                                                }
                                                placeholder={handleAppName(
                                                    lastApp
                                                )}
                                            />
                                        ) : appOptions &&
                                            appOptions.length === 0 &&
                                            state.user &&
                                            state.user?.firstName &&
                                            (state.user.role ===
                                                "Administrator" ||
                                                state.user.role ===
                                                "SuperAdministrator" ||
                                                state.user.role === undefined) ? (
                                            <Select
                                                options={appOptions}
                                                styles={customStyles}
                                                placeholder={"No apps "}
                                            />
                                        ) : (
                                            state.user &&
                                            state.user?.firstName &&
                                            (state.user.role ===
                                                "Administrator" ||
                                                state.user.role ===
                                                "SuperAdministrator") && (
                                                <CircularProgress color="inherit" />
                                            )
                                        )}
                                        {state.user && state.user.firstName && (
                                            <Select
                                                options={languageOptions}
                                                styles={customLanguageStyles}
                                                onChange={handleLanguageChange}
                                                placeholder={getLocaleKey(
                                                    navigator.language
                                                )
                                                    .toLowerCase()
                                                    .slice(0, 2)}
                                            />
                                        )}

                                        {state.user &&
                                            state.user?.firstName &&
                                            state.user?.lastName && (
                                                <Grid item xs="auto">
                                                    <Avatar
                                                        sx={{
                                                            bgcolor:
                                                                state.appId ===
                                                                    Number(
                                                                        process.env
                                                                            .REACT_APP_LINX_ID
                                                                    )
                                                                    ? "#780000"
                                                                    : "blue",
                                                            marginLeft: "20px",
                                                        }}
                                                    >
                                                        {state.user?.firstName.charAt(
                                                            0
                                                        )}
                                                        {state.user?.lastName.charAt(
                                                            0
                                                        )}
                                                    </Avatar>
                                                </Grid>
                                            )}
                                    </div>
                                </div>
                            </Toolbar>
                        </AppBar>
                        <Drawer
                            variant="permanent"
                            open={open}
                            PaperProps={{
                                sx: {
                                    backgroundColor:
                                        state.chosenLinxCountryObject &&
                                            state.chosenLinxCountryObject
                                                .themeColour
                                            ? state.chosenLinxCountryObject
                                                .themeColour
                                            : "#005591",
                                    color: "#FFFFFF",
                                },
                            }}
                        >
                            <DrawerHeader
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                {state.chosenLinxCountryObject &&
                                    state.chosenLinxCountryObject.logo ===
                                    "BOC (BOCLogo.png)" ? (
                                    <img src="boc.png" alt="boc" width={125} />
                                ) : state.chosenLinxCountryObject &&
                                    state.chosenLinxCountryObject.logo ===
                                    "Afrox (AfroxLogo.png)" ? (
                                    <img
                                        src="afox.png"
                                        alt="afrox"
                                        width={125}
                                    />
                                ) : (
                                    <img
                                        src="Logo.png"
                                        alt="logo"
                                        width={125}
                                    />
                                )}

                                <IconButton onClick={handleDrawerClose}>
                                    {theme.direction === "rtl" ? (
                                        <ChevronRightIcon />
                                    ) : (
                                        <ChevronLeftIcon
                                            sx={{ color: "white" }}
                                        />
                                    )}
                                </IconButton>
                            </DrawerHeader>
                            <Divider />
                            <List>
                                {!state.user?.id && (
                                    <ListItem
                                        disablePadding
                                        sx={{ display: "block" }}
                                        onClick={() => {
                                            instance
                                                .loginRedirect(loginRequest)
                                                .catch((e) => {
                                                    alert(
                                                        "Currently not available! Plase try again later!"
                                                    );
                                                });
                                        }}
                                    >
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open
                                                    ? "initial"
                                                    : "center",
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : "auto",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <LoginIcon
                                                    sx={{ color: "white" }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={t(
                                                    "signInButton.Externals"
                                                )}
                                                sx={{ opacity: open ? 1 : 0 }}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                )}
                                {state.user?.id && (
                                    <>
                                        <ListItem
                                            disablePadding
                                            sx={{ display: "block" }}
                                            onClick={() => {
                                                navigate("/");
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open
                                                        ? "initial"
                                                        : "center",
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : "auto",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <HomeIcon
                                                        sx={{ color: "white" }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={t(
                                                        "sidebar.navigation.home"
                                                    )}
                                                    sx={{
                                                        opacity: open ? 1 : 0,
                                                    }}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                        {state.appId === 1 &&
                                            appOptions.length > 0 &&
                                            state.user.role ===
                                            "SuperAdministrator" ? (
                                            <ListItem
                                                disablePadding
                                                sx={{
                                                    display: "block",
                                                    marginLeft: "20px",
                                                }}
                                                onClick={() =>
                                                    navigate(
                                                        "/business-segments"
                                                    )
                                                }
                                            >
                                                <ListItemButton
                                                    sx={{
                                                        minHeight: 48,
                                                        justifyContent: open
                                                            ? "initial"
                                                            : "center",
                                                        px: 2.5,
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        sx={{
                                                            minWidth: 0,
                                                            mr: open
                                                                ? 3
                                                                : "auto",
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <BusinessIcon
                                                            sx={{
                                                                color: "white",
                                                            }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={t(
                                                            "sidebar.navigation.businessSegments"
                                                        )}
                                                        sx={{
                                                            opacity: open
                                                                ? 1
                                                                : 0,
                                                        }}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        ) : null}
                                        {(state.appId === 1 ||
                                            state.appId === 3) &&
                                            (state.user.role ===
                                                "Administrator" ||
                                                state.user.role ===
                                                "SuperAdministrator") &&
                                            state.appId !== null &&
                                            appOptions.length > 0 && (
                                                <>
                                                    <ListItem
                                                        disablePadding
                                                        sx={{
                                                            display: "block",
                                                            marginLeft: "20px",
                                                        }}
                                                        onClick={() =>
                                                            navigate(
                                                                "/approval-list"
                                                            )
                                                        }
                                                    >
                                                        <ListItemButton
                                                            sx={{
                                                                minHeight: 48,
                                                                justifyContent:
                                                                    open
                                                                        ? "initial"
                                                                        : "center",
                                                                px: 2.5,
                                                            }}
                                                        >
                                                            <ListItemIcon
                                                                sx={{
                                                                    minWidth: 0,
                                                                    mr: open
                                                                        ? 3
                                                                        : "auto",
                                                                    justifyContent:
                                                                        "center",
                                                                }}
                                                            >
                                                                <InboxIcon
                                                                    sx={{
                                                                        color: "white",
                                                                    }}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={t(
                                                                    "sidebar.navigation.myEntitlementRequests"
                                                                )}
                                                                sx={{
                                                                    opacity:
                                                                        open
                                                                            ? 1
                                                                            : 0,
                                                                }}
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                    {appOptions.length > 0 ? (
                                                        <>
                                                            <ListItem
                                                                disablePadding
                                                                sx={{
                                                                    display:
                                                                        "block",
                                                                    marginLeft:
                                                                        "20px",
                                                                }}
                                                                onClick={() =>
                                                                    navigate(
                                                                        "/adhoc-invites"
                                                                    )
                                                                }
                                                            >
                                                                <ListItemButton
                                                                    sx={{
                                                                        minHeight: 48,
                                                                        justifyContent:
                                                                            open
                                                                                ? "initial"
                                                                                : "center",
                                                                        px: 2.5,
                                                                    }}
                                                                >
                                                                    <ListItemIcon
                                                                        sx={{
                                                                            minWidth: 0,
                                                                            mr: open
                                                                                ? 3
                                                                                : "auto",
                                                                            justifyContent:
                                                                                "center",
                                                                        }}
                                                                    >
                                                                        <InboxIcon
                                                                            sx={{
                                                                                color: "white",
                                                                            }}
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={t(
                                                                            "sidebar.navigation.adHocInvites"
                                                                        )}
                                                                        sx={{
                                                                            opacity:
                                                                                open
                                                                                    ? 1
                                                                                    : 0,
                                                                        }}
                                                                    />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        </>
                                                    ) : null}
                                                    {(state.appId === 1 ||
                                                        state.appId === 3) && (
                                                            <>
                                                                <ListItem
                                                                    disablePadding
                                                                    sx={{
                                                                        display:
                                                                            "block",
                                                                        marginLeft:
                                                                            "20px",
                                                                    }}
                                                                    onClick={() =>
                                                                        navigate(
                                                                            "/audit"
                                                                        )
                                                                    }
                                                                >
                                                                    <ListItemButton
                                                                        sx={{
                                                                            minHeight: 48,
                                                                            justifyContent:
                                                                                open
                                                                                    ? "initial"
                                                                                    : "center",
                                                                            px: 2.5,
                                                                        }}
                                                                    >
                                                                        <ListItemIcon
                                                                            sx={{
                                                                                minWidth: 0,
                                                                                mr: open
                                                                                    ? 3
                                                                                    : "auto",
                                                                                justifyContent:
                                                                                    "center",
                                                                            }}
                                                                        >
                                                                            <PlagiarismOutlinedIcon
                                                                                sx={{
                                                                                    color: "white",
                                                                                }}
                                                                            />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary={t(
                                                                                "sidebar.navigation.audits"
                                                                            )}
                                                                            sx={{
                                                                                opacity:
                                                                                    open
                                                                                        ? 1
                                                                                        : 0,
                                                                            }}
                                                                        />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <ListItem
                                                                    disablePadding
                                                                    sx={{
                                                                        display:
                                                                            "block",
                                                                        marginLeft:
                                                                            "20px",
                                                                    }}
                                                                    onClick={() =>
                                                                        navigate(
                                                                            "/user-activity"
                                                                        )
                                                                    }
                                                                >
                                                                    <ListItemButton
                                                                        sx={{
                                                                            minHeight: 48,
                                                                            justifyContent:
                                                                                open
                                                                                    ? "initial"
                                                                                    : "center",
                                                                            px: 2.5,
                                                                        }}
                                                                    >
                                                                        <ListItemIcon
                                                                            sx={{
                                                                                minWidth: 0,
                                                                                mr: open
                                                                                    ? 3
                                                                                    : "auto",
                                                                                justifyContent:
                                                                                    "center",
                                                                            }}
                                                                        >
                                                                            <ManageAccountsIcon
                                                                                sx={{
                                                                                    color: "white",
                                                                                }}
                                                                            />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary={t(
                                                                                "sidebar.navigation.usersManagement"
                                                                            )}
                                                                            sx={{
                                                                                opacity:
                                                                                    open
                                                                                        ? 1
                                                                                        : 0,
                                                                            }}
                                                                        />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            </>
                                                        )}
                                                </>
                                            )}
                                        {state.appId ===
                                            Number(
                                                process.env.REACT_APP_LINX_ID
                                            ) && (
                                                <>
                                                    {state.linxRoles ===
                                                        "LindeLinxBusinessAdministrator" && (
                                                            <ListItem
                                                                disablePadding
                                                                sx={{
                                                                    display: "block",
                                                                    marginLeft: "20px",
                                                                }}
                                                                onClick={() =>
                                                                    navigate("/plants")
                                                                }
                                                            >
                                                                <ListItemButton
                                                                    sx={{
                                                                        minHeight: 48,
                                                                        justifyContent:
                                                                            open
                                                                                ? "initial"
                                                                                : "center",
                                                                        px: 2.5,
                                                                    }}
                                                                >
                                                                    <ListItemIcon
                                                                        sx={{
                                                                            minWidth: 0,
                                                                            mr: open
                                                                                ? 3
                                                                                : "auto",
                                                                            justifyContent:
                                                                                "center",
                                                                        }}
                                                                    >
                                                                        <StoreIcon
                                                                            sx={{
                                                                                color: "white",
                                                                            }}
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={t(
                                                                            "sidebar.navigation.plants"
                                                                        )}
                                                                        sx={{
                                                                            opacity:
                                                                                open
                                                                                    ? 1
                                                                                    : 0,
                                                                        }}
                                                                    />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        )}
                                                    {(state.linxRoles ===
                                                        "LindeLinxBusinessAdministrator" ||
                                                        state.linxRoles ===
                                                        "LindeLinxBusinessInviter" ||
                                                        state.linxRoles ===
                                                        "AgentManager") && (
                                                            <ListItem
                                                                disablePadding
                                                                sx={{
                                                                    display: "block",
                                                                    marginLeft: "20px",
                                                                }}
                                                                onClick={() =>
                                                                    navigate(
                                                                        "/contacts"
                                                                    )
                                                                }
                                                            >
                                                                <ListItemButton
                                                                    sx={{
                                                                        minHeight: 48,
                                                                        justifyContent:
                                                                            open
                                                                                ? "initial"
                                                                                : "center",
                                                                        px: 2.5,
                                                                    }}
                                                                >
                                                                    <ListItemIcon
                                                                        sx={{
                                                                            minWidth: 0,
                                                                            mr: open
                                                                                ? 3
                                                                                : "auto",
                                                                            justifyContent:
                                                                                "center",
                                                                        }}
                                                                    >
                                                                        <PermContactCalendarIcon
                                                                            sx={{
                                                                                color: "white",
                                                                            }}
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={t(
                                                                            "sidebar.navigation.contacts"
                                                                        )}
                                                                        sx={{
                                                                            opacity:
                                                                                open
                                                                                    ? 1
                                                                                    : 0,
                                                                        }}
                                                                    />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        )}
                                                    {(state.linxRoles ===
                                                        "LindeLinxBusinessAdministrator" ||
                                                        state.linxRoles ===
                                                        "LindeLinxBusinessInviter") &&
                                                        state.chosenLinxCountryObject && state.chosenLinxCountryObject.companySupported === true && (
                                                            <ListItem
                                                                disablePadding
                                                                sx={{
                                                                    display: "block",
                                                                    marginLeft: "20px",
                                                                }}
                                                                onClick={() =>
                                                                    navigate(
                                                                        "/companies"
                                                                    )
                                                                }
                                                            >
                                                                <ListItemButton
                                                                    sx={{
                                                                        minHeight: 48,
                                                                        justifyContent:
                                                                            open
                                                                                ? "initial"
                                                                                : "center",
                                                                        px: 2.5,
                                                                    }}
                                                                >
                                                                    <ListItemIcon
                                                                        sx={{
                                                                            minWidth: 0,
                                                                            mr: open
                                                                                ? 3
                                                                                : "auto",
                                                                            justifyContent:
                                                                                "center",
                                                                        }}
                                                                    >
                                                                        <PermContactCalendarIcon
                                                                            sx={{
                                                                                color: "white",
                                                                            }}
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={t(
                                                                            "sidebar.navigation.companies"
                                                                        )}
                                                                        sx={{
                                                                            opacity:
                                                                                open
                                                                                    ? 1
                                                                                    : 0,
                                                                        }}
                                                                    />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        )}
                                                    {(state.linxRoles ===
                                                        "LindeLinxBusinessAdministrator" ||
                                                        state.linxRoles ===
                                                        "LindeLinxBusinessInviter") && (
                                                            <ListItem
                                                                disablePadding
                                                                sx={{
                                                                    display: "block",
                                                                    marginLeft: "20px",
                                                                }}
                                                                onClick={() =>
                                                                    navigate(
                                                                        "/business-events"
                                                                    )
                                                                }
                                                            >
                                                                <ListItemButton
                                                                    sx={{
                                                                        minHeight: 48,
                                                                        justifyContent:
                                                                            open
                                                                                ? "initial"
                                                                                : "center",
                                                                        px: 2.5,
                                                                    }}
                                                                >
                                                                    <ListItemIcon
                                                                        sx={{
                                                                            minWidth: 0,
                                                                            mr: open
                                                                                ? 3
                                                                                : "auto",
                                                                            justifyContent:
                                                                                "center",
                                                                        }}
                                                                    >
                                                                        <BusinessIcon
                                                                            sx={{
                                                                                color: "white",
                                                                            }}
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={t(
                                                                            "sidebar.navigation.businessEvents"
                                                                        )}
                                                                        sx={{
                                                                            opacity:
                                                                                open
                                                                                    ? 1
                                                                                    : 0,
                                                                        }}
                                                                    />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        )}
                                                </>
                                            )}
                                        <ListItem
                                            disablePadding
                                            sx={{ display: "block" }}
                                            onClick={() =>
                                                instance.logoutRedirect()
                                            }
                                        >
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open
                                                        ? "initial"
                                                        : "center",
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : "auto",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <LogoutIcon
                                                        sx={{ color: "white" }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={t(
                                                        "button.signOut"
                                                    )}
                                                    sx={{
                                                        opacity: open ? 1 : 0,
                                                    }}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </>
                                )}
                            </List>
                        </Drawer>
                        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                            <DrawerHeader />
                            {children}
                        </Box>
                    </Box>
                ) : (
                    <>
                        <LinearProgress />
                        <Stack spacing={0}>
                            <Skeleton
                                variant="rectangular"
                                width="100vw"
                                height={64}
                            />
                            <Skeleton
                                variant="rectangular"
                                width={300}
                                height="100vh"
                            />
                        </Stack>
                    </>
                )
            ) : (
                state.user && state.user.firstName && <Position />
            )}
        </>
    );
};
export default Layout;
