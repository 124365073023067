import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context";
import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Input,
    InputLabel,
    CircularProgress,
} from "@mui/material";
import { api } from "../../services";
import { generateToken } from "../../services/fetch-api/auth/authToken";
import { useMsal } from "@azure/msal-react";
import Select from "react-select";
import { IEditBS } from "../../types";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { timezones } from "../../assets/timezones";
import DeleteIcon from "@mui/icons-material/Delete";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import i18n from "../../i18n";
import { InputContainer } from "../../styled";

const defaultValues = {
    internalId: 0,
    externalId: "",
    countryCode: "",
    defaultTimezone: "",
    isActive: false,
    IntelexRequired: false,
    separator: "",
    configArray: [],
    mandatoryConfig: [],
};

const AddBusinessSegment = () => {
    const { state, dispatch } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const [formValues, setFormValues] = useState<IEditBS>(defaultValues);
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set<number>());
    const [allLanguageCodes, setAllLanguageCodes] = useState([]);
    const [timezonesArray, setTimezonesArray] = useState<any[]>([]);
    const [chosenFieldsArray, setChosenFieldsArray] = useState<any[]>([]);
    const [mandatoryFields, setMandatoryFields] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [fieldsArray, setFieldsArray] = useState<any[]>([]);

    const { t } = useTranslation();
    const steps = [
        t("label.EnterInfo"),
        t("label.SelectFields"),
        t("label.SelectMandatory"),
    ];

    useEffect(() => {
        state.user &&
            state.user?.firstName &&
            state.user?.lastName &&
            api.userDetails
                .getSupportedLanguages(state.appId)
                .then((res: any) => {
                    if (!res) return;
                    setAllLanguageCodes(
                        res.map((data: any) => {
                            return { value: data.value, label: data.value };
                        })
                    );
                    setTimezonesArray(
                        timezones.map((data: any) => {
                            return { value: data.text, label: data.text };
                        })
                    );
                });
    }, [state.user]);

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleInputChange = (event: any) => {
        let errorMsg: any = document.querySelectorAll(".errorMsg");
        errorMsg.forEach((e: any) => {
            e.style.display = "none";
        });
        const { name, value } = event.target;
        if (event.target.type === "number") {
            setFormValues({
                ...formValues,
                [name]: parseInt(value),
            });
        } else {
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
    };

    const fieldsOptionsIntelex = [
        "SubAuthorisationID",
        "CountryCode",
        "UserSupportInfoHierarchy1",
        "UserSupportInfoHierarchy2",
        "UserSupportInfoHierarchy3",
        "FirstName",
        "LastName",
        "Email",
        "PhoneNbr",
        "SupportInfo1",
        "SupportInfo2",
        "SupportInfo3",
        "LindeManagerCPID",
        "BusinessRelationship",
        "OrgUnitLevel1",
        "OrgUnitLevel2",
        "OrgUnitLevel3",
        "OrgUnitLevel4",
        "OrgUnitLevel5",
        "ExternalPosition",
        "Location",
        "CornerstoneData1",
        "CornerstoneData2",
        "CornerstoneData3",
        "CornerstoneData4",
        "Locale",
        "LocationCode",
        "SupervisorNumber",
        "PositionTitle",
        "Company",
    ];

    const fieldsOptions = [
        "SubAuthorisationID",
        "CountryCode",
        "UserSupportInfoHierarchy1",
        "UserSupportInfoHierarchy2",
        "UserSupportInfoHierarchy3",
        "FirstName",
        "LastName",
        "Email",
        "PhoneNbr",
        "SupportInfo1",
        "SupportInfo2",
        "SupportInfo3",
        "LindeManagerCPID",
        "BusinessRelationship",
        "OrgUnitLevel1",
        "OrgUnitLevel2",
        "OrgUnitLevel3",
        "OrgUnitLevel4",
        "OrgUnitLevel5",
        "ExternalPosition",
        "Location",
        "CornerstoneData1",
        "CornerstoneData2",
        "CornerstoneData3",
        "CornerstoneData4",
        "Locale",
    ];

    useEffect(() => {
        setFieldsArray(
            formValues.IntelexRequired === false
                ? fieldsOptions
                : fieldsOptionsIntelex
        );
    }, [formValues.IntelexRequired]);

    const handleSelectionChange = (event: any) => {
        setChosenFieldsArray((oldArray) => [...oldArray, event.target.value]);
        setFieldsArray((prevState: any) =>
            prevState.map((data: any) => {
                return data !== event.target.value && data;
            })
        );
    };

    useEffect(() => {
        setFormValues({ ...formValues, configArray: chosenFieldsArray });
    }, [chosenFieldsArray]);

    const handleSelectMandatory = (event: any) => {
        if (event.target.checked === true) {
            setMandatoryFields((oldArray) => [...oldArray, event.target.value]);
        }
    };

    useEffect(() => {
        setFormValues({ ...formValues, mandatoryConfig: mandatoryFields });
    }, [mandatoryFields]);

    const removeItem = (event: any) => {
        const id = event.currentTarget.id;
        setChosenFieldsArray((prevState: any) =>
            prevState.map((data: any) => {
                return data !== id && data;
            })
        );

        setFieldsArray((prevState) => [...prevState, id]);
    };

    const handleSelectAll = () => {
        setChosenFieldsArray(
            formValues.IntelexRequired === true
                ? fieldsOptionsIntelex
                : fieldsOptions
        );
        setFieldsArray([]);
    };

    const handleClearAll = () => {
        setChosenFieldsArray([]);
        setFieldsArray(
            formValues.IntelexRequired === true
                ? fieldsOptionsIntelex
                : fieldsOptions
        );
    };

    const createBusinessSegment = () => {
        setIsLoading(true);
        api.entitlement
            .createBusinessSegment(
                state.user,
                formValues,
                generateToken(instance, accounts)
            )
            .then((res: any) => {
                if (!res) {
                    setIsLoading(false);
                    return;
                } else {
                    const message = i18n.t("successMessage.bsCreated");
                    toast.error(message);
                    setIsLoading(false);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
            });
    };

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    marginTop: "30px",
                    height: "calc(100vh - 300px)",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    backgroundColor: "#F6FCFF",
                    padding: "25px",
                    borderRadius: "10px",
                }}
            >
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mx: "auto" }}>
                            Business Segment Successfully Added
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                pt: 2,
                            }}
                        >
                            <Box sx={{ flex: "1 1 auto" }} />
                            <Button onClick={handleReset}>
                                Add another business segment
                            </Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {activeStep === 0 ? (
                            <Box
                                sx={{
                                    width: "calc(100vh-30px)",
                                    margin: "20px",
                                }}
                            >
                                <Grid
                                    container
                                    columnSpacing={6}
                                    sx={{ marginTop: "15px" }}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                        sx={{ marginBottom: "px" }}
                                    >
                                        <InputContainer>
                                            <InputLabel htmlFor="external-id">
                                                {t("label.ExternalId")}
                                            </InputLabel>
                                            <Input
                                                fullWidth
                                                id="external-id"
                                                name="externalId"
                                                value={formValues.externalId}
                                                onChange={handleInputChange}
                                            />
                                        </InputContainer>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <InputContainer>
                                            <InputLabel htmlFor="separator">
                                                {t("label.Separator")}
                                            </InputLabel>
                                            <Input
                                                fullWidth
                                                id="separator"
                                                name="separator"
                                                value={formValues.separator}
                                                onChange={handleInputChange}
                                            />
                                        </InputContainer>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    columnSpacing={6}
                                    sx={{ marginTop: "15px" }}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                        sx={{ marginBottom: "px" }}
                                    >
                                        <InputContainer>
                                            <InputLabel htmlFor="country-code">
                                                {t("label.locale")}
                                            </InputLabel>
                                            <Select
                                                placeholder={
                                                    formValues.countryCode
                                                }
                                                id="country-code"
                                                name="countryCode"
                                                options={allLanguageCodes}
                                                value={formValues.countryCode}
                                                onChange={(event: any) => {
                                                    setFormValues({
                                                        ...formValues,
                                                        countryCode:
                                                            event.value,
                                                    });
                                                }}
                                            />
                                        </InputContainer>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sx={{ marginBottom: "px" }}
                                    >
                                        <InputContainer>
                                            <InputLabel htmlFor="default-timezone">
                                                {t("label.TimeZone")}
                                            </InputLabel>
                                            <Select
                                                placeholder={
                                                    formValues.defaultTimezone
                                                }
                                                id="default-timezone"
                                                name="defaultTimezone"
                                                options={timezonesArray}
                                                value={
                                                    formValues.defaultTimezone
                                                }
                                                onChange={(event: any) => {
                                                    setFormValues({
                                                        ...formValues,
                                                        defaultTimezone:
                                                            event.value,
                                                    });
                                                }}
                                            />
                                        </InputContainer>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    columnSpacing={6}
                                    sx={{ marginTop: "15px" }}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                        sx={{ marginBottom: "px" }}
                                    >
                                        <FormControlLabel
                                            label={t("label.Active")}
                                            control={
                                                <Checkbox
                                                    checked={
                                                        formValues.isActive
                                                    }
                                                    onChange={(event) => {
                                                        setFormValues({
                                                            ...formValues,
                                                            isActive:
                                                                event.target
                                                                    .checked,
                                                        });
                                                    }}
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sx={{ marginBottom: "px" }}
                                    >
                                        <FormControlLabel
                                            label={t("label.IntelexRequired")}
                                            control={
                                                <Checkbox
                                                    checked={
                                                        formValues.IntelexRequired
                                                    }
                                                    onChange={(event) => {
                                                        setFormValues({
                                                            ...formValues,
                                                            IntelexRequired:
                                                                event.target
                                                                    .checked,
                                                        });
                                                    }}
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        ) : activeStep === 1 ? (
                            <Grid
                                container
                                columnSpacing={2}
                                sx={{ marginTop: "30px" }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: "white",
                                        display: "flex",
                                        flexDirection: "column",
                                        ml: 3,
                                        width: "45%",
                                        padding: "15px",
                                        borderRadius: "12px",
                                        height: "50vh",
                                        overflowY: "scroll",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <p>{t("label.ChooseFileds")}</p>
                                        <Button onClick={handleSelectAll}>
                                            {t("label.SelectAll")}
                                        </Button>
                                    </div>
                                    {fieldsArray.map((data: any) => {
                                        return (
                                            data !== false && (
                                                <FormControlLabel
                                                    label={data}
                                                    value={data}
                                                    control={<Checkbox />}
                                                    onChange={
                                                        handleSelectionChange
                                                    }
                                                />
                                            )
                                        );
                                    })}
                                </Box>

                                <Box
                                    sx={{
                                        backgroundColor: "white",
                                        display: "block",
                                        flexDirection: "column",
                                        ml: 3,
                                        width: "45%",
                                        padding: "15px",
                                        borderRadius: "12px",
                                        height: "50vh",
                                        overflowY: "scroll",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <p>Chosen Fields</p>
                                        <Button onClick={handleClearAll}>
                                            {t("label.ClearAll")}
                                        </Button>
                                    </div>
                                    {chosenFieldsArray.map((data) => {
                                        return (
                                            data !== false && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "space-between",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <p
                                                        style={{ margin: 0 }}
                                                        id={data}
                                                    >
                                                        {data}
                                                    </p>
                                                    <IconButton
                                                        onClick={removeItem}
                                                        id={data}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            )
                                        );
                                    })}
                                </Box>
                            </Grid>
                        ) : (
                            <Grid
                                container
                                columnSpacing={2}
                                sx={{ marginTop: "30px" }}
                            >
                                <Box
                                    sx={{
                                        ml: 3,
                                        width: "100%",
                                        padding: "15px",
                                        borderRadius: "12px",
                                        height: "50vh",
                                        backgroundColor: "white",
                                        overflowY: "scroll",
                                    }}
                                >
                                    <p>{t("label.ChooseMandatory")}</p>
                                    <div
                                        style={{
                                            display: "grid",
                                            gridTemplateColumns:
                                                "auto auto auto",
                                        }}
                                    >
                                        {chosenFieldsArray.map((data) => {
                                            return (
                                                data !== false && (
                                                    <FormControlLabel
                                                        label={data}
                                                        value={data}
                                                        control={<Checkbox />}
                                                        onChange={
                                                            handleSelectMandatory
                                                        }
                                                    />
                                                )
                                            );
                                        })}
                                    </div>
                                </Box>
                            </Grid>
                        )}

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                pt: 2,
                            }}
                        >
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                {t("label.Back")}
                            </Button>
                            <Box sx={{ flex: "1 1 auto" }} />

                            {activeStep === steps.length - 1 ? (
                                isLoading ? (
                                    <CircularProgress />
                                ) : (
                                    <Button onClick={createBusinessSegment}>
                                        {t("label.Finish")}
                                    </Button>
                                )
                            ) : (
                                <Button onClick={handleNext}>
                                    {t("label.Next")}
                                </Button>
                            )}
                        </Box>
                    </React.Fragment>
                )}
            </Box>
        </>
    );
};

export default AddBusinessSegment;
