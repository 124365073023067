import { useEffect, useState, useContext } from "react";
import styled from "@emotion/styled";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, Radio } from "@mui/material";
import { UserContext } from "../../context";


export default function TreeNode({ node, searchQuery, onSelect, selectedItem }: any) {
    const [isExpanded, setIsExpanded] = useState(false);
    const { state, dispatch } = useContext(UserContext);

    function handleClick() {
        if (onSelect) {
            onSelect(node);
        }
    }


    if (node.data && !node.data.name.toLowerCase().includes(searchQuery.toLowerCase())) {
        return null;
    }

    if (node.children && node.children.length > 0) {
        return (
            <li style={{ listStyle: 'none', alignItems: 'center' }}>
                <li style={{ display: 'flex', alignItems: 'center' }} >
                    {isExpanded ? <ExpandMoreIcon onClick={() => setIsExpanded(!isExpanded)} /> : <ChevronRightIcon onClick={() => setIsExpanded(!isExpanded)} />}
                    <p
                        onClick={handleClick}
                        style={{ width: '100%', padding: '2px 6px', marginLeft: 2, marginTop: 1, marginBottom: 1, cursor: 'pointer', backgroundColor: state.orgUnit && state.orgUnit.data ? (node.data.internalId === state.orgUnit.data.internalId ? '#dfeeff' : 'white') : state.orgUnit ? (node.data.internalId === state.orgUnit.internalId ? '#dfeeff' : 'white') : 'white' }}>
                        {node.data.name}
                    </p>
                </li>
                {isExpanded && (
                    <ul>
                        {node.children.map((child: any) => (
                            <TreeNode
                                key={child.internalId}
                                node={child}
                                searchQuery={searchQuery}
                                onSelect={onSelect}
                            />
                        ))}
                    </ul>
                )}
            </li>
        );
    } else {
        return (
            <li style={{ listStyle: 'none' }}>
                {node.data && <div onClick={handleClick}><p style={{ padding: '2px 6px', marginLeft: 2, marginTop: 1, marginBottom: 1, backgroundColor: state.orgUnit && node.data.internalId === state.orgUnit.data.internalId ? '#dfeeff' : 'white', cursor: 'pointer' }}>{node.data.name}</p></div>}
            </li>
        );
    }
}
