import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Grid,
    Input,
    InputLabel,
    LinearProgress,
    TextField,
} from "@mui/material";
import React, { useContext, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context";
import { useMsal } from "@azure/msal-react";
import { api } from "../../services";
import { generateToken } from "../../services/fetch-api/auth/authToken";
import TreeView from "../../components/treeview";
import TreeSearch from "../../components/treeview/TreeSearch";
import SaveIcon from "@mui/icons-material/Save";
import AdHocManagersFields from "./AdHocManagersFields";
import { toast } from "react-toastify";
import type {
    ILocation,
    IAutocomplete,
    IBusinessRelationship,
    IMandatoryFields,
    IBusinessRelationshipState,
} from "../../types/index";
import { InputContainer, CornerStoneData, Error } from "../../styled/index";
import { defaultValues, defaultSearchValues } from "../../constants/index";
import { handleRequiredInput, handleEmptyInputs } from "../../helpers/index";

const AdHocElevateOffline = () => {
    const { state, dispatch } = useContext(UserContext);
    const { t } = useTranslation();
    const { instance, accounts } = useMsal();
    const [countryCodes, setCountryCodes] = useState<IAutocomplete[]>([]);
    const [businessRelationships, setBusinessRealtionships] = useState<
        IBusinessRelationshipState[]
    >([]);
    const [cornerstoneLabels, setCornerstoneLabels] = useState([]);
    const [orgUnits, setOrgUnits] = useState<any[]>([]);
    const supervisorsInput = useRef<HTMLElement>(null);
    const [searchResults, setSearchResults] = useState([]);
    const [externalPositions, setExternalPositions] = useState<
        IBusinessRelationshipState[]
    >([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedItem, setSelectedItem] = useState<any>();
    const [userSupportInfo, setUserSupportInfo] = useState([]);
    const [supportInfo, setSupportInfo] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [lindeManagers, setLindeManagers] = useState([]);
    const [mandatoryFields, setMandatoryFields] = useState<IMandatoryFields>();
    const [countryCodeLoading, setCountryCodeLoading] = useState(false);
    const [businessRelationshipLoading, setBusinessRelationshipLoading] =
        useState(false);
    const [externalPositionsLoading, setExternalPositionsLoading] =
        useState(false);
    const [locationLoading, setLocationLoading] = useState(false);
    const [languageLoading, setLanguageLoading] = useState(false);
    const [CSDataLoading, setCSDataLoading] = useState(false);
    const [USIHLoading, setUSIHLoading] = useState(false);
    const [SILoading, setSILoading] = useState(false);
    const [lindeManagersLoading, setLindeManagersLoading] = useState(false);
    const [mandatoryLoading, setMandatoryLoading] = useState(true);
    const [lindeManagerRequired, setLindeManagerRequired] = useState(false);
    const [managersMandatoryOnSubmit, setManagersMandatoryOnSubmit] =
        useState(false);
    const [formValues, setFormValues] = useState<any>(defaultValues);
    const [managersSearchValues, setManagersSearchValues] =
        useState<any>(defaultSearchValues);

    const handleSubmit = (event: any) => {
        if (handleEmptyInputs(mandatoryFields, formValues) === false) {
            event.preventDefault();
            setIsSubmitLoading(true);
            api.adHocInvite
                .createAdHocOfflineInvite(
                    state.user,
                    state.chosenBusinessSegment.internalId,
                    1,
                    formValues,
                    generateToken(instance, accounts)
                )
                .then((res) => {
                    if (res === null) {
                        setIsSubmitLoading(false);
                        return;
                    }
                    setIsSubmitLoading(false);
                    api.approvalList
                        .approveOfflineEntitlement(
                            state.user,
                            [res.data],
                            generateToken(instance, accounts)
                        )
                        .then((res: any) => { })
                        .catch((err: any) => {
                            toast.error(err.message);
                        });
                });
        } else {
            handleRequiredInput(
                event,
                mandatoryFields,
                formValues,
                setManagersMandatoryOnSubmit,
                setLindeManagerRequired,
                state
            );
        }
    };

    const handleInputChange = (event: any) => {
        let errorMsg: any = document.querySelectorAll(".errorMsg");
        errorMsg.forEach((e: any) => {
            e.style.display = "none";
            setManagersMandatoryOnSubmit(false);
        });
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleAutocompleteChange = (
        value: string | IAutocomplete | null | string,
        name: string
    ) => {
        let errorMsg: any = document.querySelectorAll(".errorMsg");
        errorMsg.forEach((e: any) => {
            e.style.display = "none";
            setManagersMandatoryOnSubmit(false);
        });

        setFormValues({
            ...formValues,
            [name]: value || "",
        });
    };

    useEffect(() => {
        if (selectedItem && selectedItem.data) {
            setFormValues({
                ...formValues,
                orgUnitExternalId: selectedItem.data.externalId,
                orgUnitParentId: selectedItem.data.parentId,
            });
        } else if (selectedItem && !selectedItem.data) {
            setFormValues({
                ...formValues,
                orgUnitExternalId: selectedItem.externalId,
                orgUnitParentId: selectedItem.parentId,
            });
        }
    }, [selectedItem]);

    useEffect(() => {
        setLindeManagersLoading(true);
        api.adHocInvite
            .getLindeManagers(
                state.user,
                generateToken(instance, accounts),
                managersSearchValues
            )
            .then((res: any) => {
                setLindeManagersLoading(false);
                setLindeManagers(
                    res.data.map((data: any) => ({
                        id: data.user_ref,
                        label:
                            data.user_name_first +
                            "  " +
                            data.user_name_last +
                            " (" +
                            data.user_email +
                            ")",
                    }))
                );
            });
    }, [managersSearchValues]);

    useEffect(() => {
        if (!state.user || !state.chosenBusinessSegment) return;
        setCountryCodeLoading(true);
        api.adHocInvite
            .getCountryCodes(state.user, generateToken(instance, accounts))
            .then((res: ILocation[]) => {
                if (!res) return;
                setCountryCodeLoading(false);
                setCountryCodes(
                    res.map((loc) => ({
                        id: loc.alpha3,
                        label: loc.alpha3 + " - " + loc.name,
                    }))
                );
            });

        setBusinessRelationshipLoading(true);
        state.chosenBusinessSegment.internalId !== 1 &&
            api.adHocInvite
                .getBusinessRelationships(
                    state.user,
                    state.chosenBusinessSegment.internalId,
                    generateToken(instance, accounts)
                )
                .then((res: IBusinessRelationship[]) => {
                    setBusinessRelationshipLoading(false);
                    setBusinessRealtionships(
                        res.map((relation) => ({
                            id: relation.internalId,
                            label: relation.name,
                        }))
                    );
                });

        state.chosenBusinessSegment.internalId !== 1 &&
            api.adHocInvite
                .getOrgUnits(
                    state.user,
                    state.chosenBusinessSegment.internalId,
                    generateToken(instance, accounts)
                )
                .then((res: any) => {
                    setOrgUnits(res);
                });

        setExternalPositionsLoading(true);
        state.chosenBusinessSegment.internalId !== 1 &&
            api.adHocInvite
                .getExternalPositions(
                    state.user,
                    state.chosenBusinessSegment.internalId,
                    generateToken(instance, accounts)
                )
                .then((res: IBusinessRelationship[]) => {
                    setExternalPositionsLoading(false);
                    setExternalPositions(
                        res.map((position) => ({
                            id: position.internalId,
                            label: position.name,
                        }))
                    );
                });

        setCSDataLoading(true);
        state.chosenBusinessSegment.internalId !== 1 &&
            api.adHocInvite
                .getCornerstoneLabels(
                    state.user,
                    state.chosenBusinessSegment.internalId,
                    state.appId,
                    generateToken(instance, accounts)
                )
                .then((res: any) => {
                    setCSDataLoading(false);
                    setCornerstoneLabels(
                        res.filter((data: any) => {
                            if (data.label !== "") {
                                return {
                                    id: data.parameter,
                                    name: data.label,
                                };
                            }
                        })
                    );
                });

        setUSIHLoading(true);
        state.chosenBusinessSegment.internalId !== 1 &&
            api.adHocInvite
                .getUserSupportInfo(
                    state.user,
                    state.chosenBusinessSegment.internalId,
                    state.appId,
                    generateToken(instance, accounts)
                )
                .then((res: any) => {
                    setUSIHLoading(false);
                    setUserSupportInfo(
                        res.filter((data: any) => {
                            if (data.label !== "") {
                                return {
                                    id: data.parameter,
                                    name: data.label,
                                };
                            }
                        })
                    );
                });

        setSILoading(true);
        state.chosenBusinessSegment.internalId !== 1 &&
            api.adHocInvite
                .getSupportInfo(
                    state.user,
                    state.chosenBusinessSegment.internalId,
                    state.appId,
                    generateToken(instance, accounts)
                )
                .then((res: any) => {
                    setSILoading(false);
                    setSupportInfo(
                        res.filter((data: any) => {
                            if (data.label !== "") {
                                return {
                                    id: data.parameter,
                                    name: data.label,
                                };
                            }
                        })
                    );
                });

        setLanguageLoading(true);
        api.userDetails.getSupportedLanguages(state.appId).then((res: any) => {
            setLanguageLoading(false);
            setLanguageOptions(res);
        });

        state.chosenBusinessSegment &&
            setFormValues({
                ...formValues,
                language: state.chosenBusinessSegment.defaultLanguage,
            });

        setMandatoryLoading(true);
        if (state.chosenBusinessSegment.intelexRequired === "Yes") {
            state.chosenBusinessSegment.internalId !== 1 &&
                api.adHocInvite
                    .getMandatoryFieldsOffline(
                        state.user,
                        state.chosenBusinessSegment.internalId,
                        1,
                        generateToken(instance, accounts)
                    )
                    .then((res: any) => {
                        setMandatoryFields(res);
                        setMandatoryLoading(false);
                    });
        } else if (state.chosenBusinessSegment.intelexRequired === "No") {
            state.chosenBusinessSegment.internalId !== 1 &&
                api.adHocInvite
                    .getMandatoryFieldsOffline(
                        state.user,
                        state.chosenBusinessSegment.internalId,
                        1,
                        generateToken(instance, accounts)
                    )
                    .then((res: any) => {
                        setMandatoryFields(res);
                        setMandatoryLoading(false);
                    });
        }
    }, [state.chosenBusinessSegment]);

    let timeoutId: any = null;

    const handleQueryChange = (event: any) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            if (
                event.target.value !== undefined &&
                event.target.value.length > 3
            ) {
                setLocationLoading(true);
                api.adHocInvite
                    .getLocationsElevate(
                        state.user,
                        event.target.value,
                        generateToken(instance, accounts)
                    )
                    .then((res: any) => {
                        setLocationLoading(false);
                        setSearchResults(res);
                    });
            }
        }, 300);
    };

    return (
        <>
            {mandatoryLoading ? (
                <LinearProgress />
            ) : (
                <Box sx={{ width: "calc(100vh-30px)", margin: "20px" }}>
                    {CSDataLoading ? <LinearProgress /> : null}
                    <Grid
                        container
                        columnSpacing={6}
                        sx={{ marginTop: "15px" }}
                    >
                        <Grid item xs={6} sx={{ position: "relative" }}>
                            {countryCodeLoading && (
                                <LinearProgress
                                    sx={{
                                        position: "absolute",
                                        left: 48,
                                        right: 0,
                                        bottom: 10,
                                    }}
                                />
                            )}
                            <InputContainer>
                                <InputLabel htmlFor="country-code">
                                    {t("label.countryCode")}
                                    {mandatoryFields &&
                                        mandatoryFields.CountryCode ===
                                        true && (
                                            <span style={{ color: "red" }}>
                                                {" "}
                                                *
                                            </span>
                                        )}
                                    <Error
                                        className="errorMsg"
                                        id="CountryCodeError"
                                    >
                                        {t("errorMessage.RequiredField")}
                                    </Error>
                                </InputLabel>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={countryCodes}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                        />
                                    )}
                                    onChange={(event: any, value: any) =>
                                        handleAutocompleteChange(
                                            value.id,
                                            "countryCode"
                                        )
                                    }
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={6} sx={{ position: "relative" }}>
                            {businessRelationshipLoading && (
                                <LinearProgress
                                    sx={{
                                        position: "absolute",
                                        left: 48,
                                        right: 0,
                                        bottom: 10,
                                    }}
                                />
                            )}
                            <InputContainer>
                                <InputLabel htmlFor="business-relationship">
                                    {t("label.businessRelationship")}
                                    {mandatoryFields &&
                                        mandatoryFields.BusinessRelationship ===
                                        true && (
                                            <span style={{ color: "red" }}>
                                                {" "}
                                                *
                                            </span>
                                        )}
                                    <Error
                                        className="errorMsg"
                                        id="BusinessRelationshipError"
                                    >
                                        {t("errorMessage.RequiredField")}
                                    </Error>
                                </InputLabel>

                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={businessRelationships}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                        />
                                    )}
                                    onChange={(event: any, value: any) =>
                                        handleAutocompleteChange(
                                            value.id,
                                            "businessRelationshipExternalId"
                                        )
                                    }
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        columnSpacing={6}
                        sx={{ marginTop: "15px" }}
                    >
                        <Grid item xs={6} sx={{ marginBottom: "px" }}>
                            <InputContainer>
                                <InputLabel htmlFor="user-name">
                                    {t("formField.firstName")}
                                    {mandatoryFields &&
                                        mandatoryFields.FirstName === true && (
                                            <span style={{ color: "red" }}>
                                                {" "}
                                                *
                                            </span>
                                        )}
                                    <Error
                                        className="errorMsg"
                                        id="FirstNameError"
                                    >
                                        {t("errorMessage.RequiredField")}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-name"
                                    name="firstName"
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={6}>
                            <InputContainer>
                                <InputLabel htmlFor="user-last-name">
                                    {t("formField.lastName")}
                                    {mandatoryFields &&
                                        mandatoryFields.LastName === true && (
                                            <span style={{ color: "red" }}>
                                                {" "}
                                                *
                                            </span>
                                        )}
                                    <Error
                                        className="errorMsg"
                                        id="LastNameError"
                                    >
                                        {t("errorMessage.RequiredField")}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-last-name"
                                    name="lastName"
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        columnSpacing={6}
                        sx={{ marginTop: "15px" }}
                    >
                        <Grid item xs={6} sx={{ marginBottom: "px" }}>
                            <InputContainer>
                                <InputLabel htmlFor="user-email">
                                    {t("formField.email")}
                                    <Error className="errorMsg" id="EmailError">
                                        {t("errorMessage.RequiredField")}
                                    </Error>
                                    <Error
                                        className="errorMsg"
                                        id="EmailInvalid"
                                    >
                                        {t("errorMessage.InvalidInput")}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-email"
                                    name="email"
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={6}>
                            <InputContainer>
                                <InputLabel htmlFor="user-phoneNbr">
                                    {t("label.phoneNumber")}
                                    <Error className="errorMsg" id="PhoneError">
                                        {t("errorMessage.RequiredField")}
                                    </Error>
                                    <Error
                                        className="errorMsg"
                                        id="PhoneInvalid"
                                    >
                                        {t("errorMessage.InvalidInput")}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-phoneNbr"
                                    name="phoneNbr"
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        columnSpacing={6}
                        sx={{ marginTop: "15px" }}
                    >
                        <Grid
                            item
                            xs={6}
                            sx={{ marginBottom: "px", position: "relative" }}
                        >
                            {externalPositionsLoading && (
                                <LinearProgress
                                    sx={{
                                        position: "absolute",
                                        left: 48,
                                        right: 0,
                                        bottom: 10,
                                    }}
                                />
                            )}
                            <InputContainer>
                                <InputLabel htmlFor="external-position">
                                    {t("label.externalPosition")}
                                    {mandatoryFields &&
                                        mandatoryFields.ExternalPositionExternalId ===
                                        true && (
                                            <span style={{ color: "red" }}>
                                                {" "}
                                                *
                                            </span>
                                        )}
                                    <Error
                                        className="errorMsg"
                                        id="ExternalPositionError"
                                    >
                                        {t("errorMessage.RequiredField")}
                                    </Error>
                                </InputLabel>
                                <Autocomplete
                                    disablePortal
                                    id="external-position"
                                    options={externalPositions}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                        />
                                    )}
                                    onChange={(event: any, value: any) =>
                                        handleAutocompleteChange(
                                            value.id,
                                            "externalPositionExternalId"
                                        )
                                    }
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={6} sx={{ position: "relative" }}>
                            {locationLoading && (
                                <LinearProgress
                                    sx={{
                                        position: "absolute",
                                        left: 48,
                                        right: 0,
                                        bottom: 27,
                                    }}
                                />
                            )}
                            <InputContainer>
                                <InputLabel htmlFor="location">
                                    {t("label.location")}
                                    {mandatoryFields &&
                                        mandatoryFields.LocationExternalId ===
                                        true && (
                                            <span style={{ color: "red" }}>
                                                {" "}
                                                *
                                            </span>
                                        )}
                                    <Error
                                        className="errorMsg"
                                        id="LocationError"
                                    >
                                        {t("errorMessage.RequiredField")}
                                    </Error>
                                </InputLabel>

                                <Autocomplete
                                    id="location"
                                    openOnFocus
                                    disablePortal
                                    options={searchResults}
                                    getOptionLabel={(option: any) =>
                                        option.name
                                    }
                                    onInputChange={(event) =>
                                        handleQueryChange(event)
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                        />
                                    )}
                                    onChange={(event: any, value: any) =>
                                        handleAutocompleteChange(
                                            value.externalId,
                                            "locationExternalId"
                                        )
                                    }
                                />
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: "11px",
                                        color: "gray",
                                    }}
                                >
                                    {t("label.threeOrMore")}
                                </p>
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        columnSpacing={6}
                        sx={{ marginTop: "15px" }}
                    ></Grid>
                    <Grid
                        container
                        columnSpacing={6}
                        sx={{ marginTop: "15px" }}
                    >
                        <Grid
                            item
                            xs={6}
                            sx={{ marginBottom: "px", position: "relative" }}
                        >
                            {languageLoading && (
                                <LinearProgress
                                    sx={{
                                        position: "absolute",
                                        left: 48,
                                        right: 0,
                                        bottom: 10,
                                    }}
                                />
                            )}
                            <InputContainer>
                                <InputLabel htmlFor="user-title">
                                    {t("label.language")}
                                    {mandatoryFields &&
                                        mandatoryFields.Language === true && (
                                            <span style={{ color: "red" }}>
                                                {" "}
                                                *
                                            </span>
                                        )}
                                    <Error
                                        className="errorMsg"
                                        id="LanguageError"
                                    >
                                        {t("errorMessage.RequiredField")}
                                    </Error>
                                </InputLabel>
                                <Autocomplete
                                    openOnFocus
                                    disablePortal
                                    id="combo-box-demo"
                                    options={languageOptions}
                                    onChange={(event, value: any) =>
                                        handleAutocompleteChange(
                                            value.value,
                                            "language"
                                        )
                                    }
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={
                                                formValues.language &&
                                                formValues.language.slice(0, 2)
                                            }
                                        />
                                    )}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>
                    <div>
                        {cornerstoneLabels.length > 0 && (
                            <>
                                <Grid
                                    container
                                    columnSpacing={6}
                                    sx={{ marginTop: "15px" }}
                                >
                                    <CornerStoneData>
                                        <legend>{t("label.csData")}</legend>
                                        {cornerstoneLabels.map((label: any) => {
                                            if (label !== undefined) {
                                                return (
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        sx={{
                                                            marginBottom: "px",
                                                        }}
                                                    >
                                                        <InputContainer>
                                                            <InputLabel
                                                                htmlFor={
                                                                    label.parameter
                                                                }
                                                            >
                                                                {label.label}
                                                            </InputLabel>
                                                            <Input
                                                                fullWidth
                                                                id={
                                                                    label.parameter
                                                                }
                                                                name={
                                                                    label.parameter
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                            />
                                                            <Error
                                                                className="errorMsg"
                                                                id="CSDataError"
                                                            >
                                                                {t(
                                                                    "errorMessage.RequiredField"
                                                                )}
                                                            </Error>
                                                        </InputContainer>
                                                    </Grid>
                                                );
                                            }
                                        })}
                                    </CornerStoneData>
                                </Grid>
                            </>
                        )}
                    </div>

                    {userSupportInfo && userSupportInfo.length > 0 && (
                        <>
                            <Grid
                                container
                                columnSpacing={6}
                                sx={{ marginTop: "15px" }}
                            >
                                <CornerStoneData>
                                    <legend>
                                        User Support Info Hierarchies:
                                    </legend>
                                    {userSupportInfo.map((label: any) => {
                                        if (label !== undefined) {
                                            return (
                                                <Grid
                                                    item
                                                    xs={3}
                                                    sx={{ marginBottom: "px" }}
                                                >
                                                    <InputContainer>
                                                        <InputLabel
                                                            htmlFor={
                                                                label.parameter
                                                            }
                                                        >
                                                            {label.label}
                                                        </InputLabel>
                                                        <Input
                                                            fullWidth
                                                            id={label.parameter}
                                                            name={
                                                                label.parameter
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                        <Error
                                                            className="errorMsg"
                                                            id="USIHError"
                                                        >
                                                            {t(
                                                                "errorMessage.RequiredField"
                                                            )}
                                                        </Error>
                                                    </InputContainer>
                                                </Grid>
                                            );
                                        }
                                    })}
                                </CornerStoneData>
                            </Grid>
                        </>
                    )}

                    {supportInfo && supportInfo.length > 0 && (
                        <>
                            <Grid
                                container
                                columnSpacing={6}
                                sx={{ marginTop: "15px" }}
                            >
                                <CornerStoneData>
                                    <legend>Support Info:</legend>
                                    {supportInfo.map((label: any) => {
                                        if (label !== undefined) {
                                            return (
                                                <Grid
                                                    item
                                                    xs={3}
                                                    sx={{ marginBottom: "px" }}
                                                >
                                                    <InputContainer>
                                                        <InputLabel
                                                            htmlFor={
                                                                label.parameter
                                                            }
                                                        >
                                                            {label.label}
                                                        </InputLabel>
                                                        <Input
                                                            fullWidth
                                                            id={label.parameter}
                                                            name={
                                                                label.parameter
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                        <Error
                                                            className="errorMsg"
                                                            id="SIError"
                                                        >
                                                            {t(
                                                                "errorMessage.RequiredField"
                                                            )}
                                                        </Error>
                                                    </InputContainer>
                                                </Grid>
                                            );
                                        }
                                    })}
                                </CornerStoneData>
                            </Grid>
                        </>
                    )}
                    <AdHocManagersFields
                        requiredMessage={lindeManagerRequired}
                        mandatory={mandatoryFields?.LindeManagerCPID}
                        managersSearchValues={managersSearchValues}
                        setManagersSearchValues={setManagersSearchValues}
                        lindeManagers={lindeManagers}
                        lindeManagersLoading={lindeManagersLoading}
                        handleAutocompleteChange={handleAutocompleteChange}
                        mandatoryOnSubmit={managersMandatoryOnSubmit}
                    />

                    <Grid
                        container
                        columnSpacing={6}
                        sx={{ marginTop: "15px" }}
                    >
                        <Grid item xs={6} sx={{ position: "relative" }}>
                            {orgUnits && (
                                <TreeSearch
                                    data={orgUnits}
                                    searchQuery={searchQuery}
                                    setSearchQuery={setSearchQuery}
                                    setSelectedItem={setSelectedItem}
                                    selectedItem={selectedItem}
                                />
                            )}
                            {orgUnits && searchQuery === "" && (
                                <TreeView
                                    children={orgUnits}
                                    selectedItem={selectedItem}
                                    setSelectedItem={setSelectedItem}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        columnSpacing={4}
                        sx={{ marginTop: "10px" }}
                    >
                        {isSubmitLoading ? (
                            <Grid
                                item
                                xs={12}
                                sx={{ textAlign: "center", marginTop: "10px" }}
                            >
                                <CircularProgress sx={{ width: "100%" }} />
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={12} sx={{ textAlign: "right" }}>
                                    <Button
                                        startIcon={<SaveIcon />}
                                        type="submit"
                                        variant="outlined"
                                        onClick={handleSubmit}
                                    >
                                        {t("formField.save")}
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Box>
            )}
        </>
    );
};

export default AdHocElevateOffline;
