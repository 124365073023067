import { IApprovalRequest, IEntitlementRequest, User } from "../../types";
import { fetchApi } from "../fetch-api";

export const createUserDetailApi = () => {

    function approveRequest(loggedUser: User, entitlementRequest: IEntitlementRequest, token: any) {
        let data: IApprovalRequest = {
            userObjectId: loggedUser.id,
            entitlementRequestId: entitlementRequest.id,
            firstName: loggedUser.firstName,
            lastName: loggedUser.lastName,
            phoneNumber: loggedUser.phone === '' ? entitlementRequest.phoneNbr : loggedUser.phone,
            email: loggedUser.email === '' ? entitlementRequest.email : loggedUser.email

        };
        return fetchApi(`api/EntitlementRequest/ApproveEntitlementRequest`, loggedUser.language, 'POST', token, data);
    }

    function getUserLocation(loggedUser: User, token: any) {
        if (loggedUser.email === 'milan.stefanovic@tron-it.com' || loggedUser.email === 'milan.stefanovic2@linde.com') {
            return fetchApi(`api/IntelexDb/GetUserLocation/uk4111@linde.com`, loggedUser.language, 'GET', token);
        }
        else {
            return fetchApi(`api/IntelexDb/GetUserLocation/${loggedUser.userIdLidap}`, loggedUser.language, 'GET', token);
        }
    }

    function getUsersLastApp(loggedUser: User, token: any) {
        if (loggedUser) {
            if (loggedUser.role === 'Administrator' || loggedUser.role === 'SuperAdministrator' || loggedUser.role === undefined || loggedUser.role === 'ExternalUser') {
                return fetchApi(`api/BusinessSegment/GetUsersLastApplication?userobjectId=${loggedUser.userIdCS}`, loggedUser.language, 'GET', token)
            }
        }
    }



    function getUsersLastBusinessSegment(loggedUser: User, token: any) {
        if (loggedUser) {
            if (loggedUser.role === 'Administrator' || loggedUser.role === 'SuperAdministrator') {
                return fetchApi(`api/BusinessSegment/GetUsersLastBusinessSegment?userobjectId=${loggedUser.userIdCS}`, loggedUser.language, 'GET', token)
            }
        }
    }

    function setUsersLastApp(loggedUser: User, token: any, appId: any) {
        if (loggedUser) {
            if (loggedUser.role === 'Administrator' || loggedUser.role === 'SuperAdministrator' || loggedUser.role === undefined || loggedUser.role === 'ExternalUser') {
                return fetchApi(`api/BusinessSegment/SetUsersLastApplication?appInfoId=${appId}&userObjectId=${loggedUser.userIdCS}`, loggedUser.language, 'POST', token);
            }
        }
    }

    function setUsersLastBusinessSegment(loggedUser: User, token: any, bsId: any) {
        if (loggedUser) {
            if (loggedUser.role === 'Administrator' || loggedUser.role === 'SuperAdministrator') {
                return fetchApi(`api/BusinessSegment/SetUsersLastBusinessSegment?lastBusinessSegmentAppIdDto=${bsId}&userObjectId=${loggedUser.userIdCS}`, loggedUser.language, 'POST', token);
            }
        }
    }

    function getSupportedLanguages(appId: number) {
        return fetchApi(`api/Configuration/GetSupportedLanguages/${appId}`, 'en - US', 'GET')
    }

    function getAdminUsers(loggedUser: User, token: any, businessSegments: any, appId: number, searchParam: string) {
        if (appId === 1 || appId === 8) {
            let params = ''
            businessSegments.businessSegments.forEach((bs: any) => {
                params = params + '&businessSegments=' + bs.internalId
            })
            return fetchApi(`api/User/GetAdminUsers/${searchParam}?${params}`, loggedUser.language, 'GET', token)
        } else if (appId === 3) {
            return fetchApi(`api/User/GetAdminUsers/${searchParam}?businessSegments=1`, loggedUser.language, 'GET', token)
        }
    }

    function getUserActionsById(loggedUser: User, token: any, objectId: string, email: string) {
        return fetchApi(`api/UserActions/GetUserActionsByEmail/${objectId}/${email}`, loggedUser.language, 'GET', token)
    }


    function getBusinessSegmentById(loggedUser: User, token: any, businessSegmentId: number) {
        return fetchApi(`api/BusinessSegment/GetByInternalId/${businessSegmentId}`, loggedUser.language, 'GET', token)
    }

    function getAllBusinessSegments(loggedUser: User, token: any) {
        return fetchApi(`/api/BusinessSegment/GetAllBusinessSegments`, loggedUser.language, 'GET', token)
    }

    function getInfoForDashboard(loggedUser: User, token: any) {
        return fetchApi(`/api/Entitlement/GetInfoForDashboard`, loggedUser.language, 'GET', token)
    }

    function changePassword(loggedUser: User, token: any, appId: any, body: any) {
        if (loggedUser) {
            if (loggedUser.role === 'Administrator' || loggedUser.role === 'SuperAdministrator') {
                return fetchApi(`api/User/ChangePassword/${appId}`, loggedUser.language, 'PUT', token, body);
            }
        }
    }

    function removeEntitlement(loggedUser: User, token: any, objectId: string, entitlementId: number) {
        if (loggedUser) {
            if (loggedUser.role === 'Administrator' || loggedUser.role === 'SuperAdministrator') {
                return fetchApi(`api/Entitlement/RemoveEntitlement/${loggedUser.id}/${objectId}?entitlementId=${entitlementId}`, loggedUser.language, 'PATCH', token);
            }
        }
    }

    return {
        approveRequest,
        getUserLocation,
        getUsersLastApp,
        setUsersLastApp,
        getUsersLastBusinessSegment,
        setUsersLastBusinessSegment,
        getSupportedLanguages,
        getAdminUsers,
        getUserActionsById,
        getBusinessSegmentById,
        getAllBusinessSegments,
        changePassword,
        removeEntitlement,
        getInfoForDashboard
    };
};


