import React, { useState } from "react";
import AddBusinessSegment from "./AddBusinessSegment";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AllBusinessSegments from "./AllBusinessSegments";
import { useTranslation } from "react-i18next";
import { TabPanelProps } from "../../types";

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const BusinessSegments = () => {
    const [value, setValue] = useState(0);
    const { t } = useTranslation();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label={t("label.AddBs")} {...a11yProps(0)} />
                        <Tab label={t("label.AllBss")} {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <AddBusinessSegment />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <AllBusinessSegments />
                </TabPanel>
            </Box>
        </>
    );
};

export default BusinessSegments;
