import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context";
import { Box, LinearProgress, Modal } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { api } from "../../services";
import { generateToken } from "../../services/fetch-api/auth/authToken";
import { useMsal } from "@azure/msal-react";
import { IEditBS } from "../../types";
import { useTranslation } from "react-i18next";
import {
    DataGrid,
    GridActionsCellItem,
    GridApi,
    GridCellValue,
    GridColDef,
} from "@mui/x-data-grid";
import EditBusinessSegment from "./EditBusinessSegment";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
};

const defaultValues = {
    internalId: 0,
    externalId: "",
    countryCode: "",
    defaultTimezone: "",
    isActive: false,
    IntelexRequired: false,
    configArray: [],
    mandatoryConfig: [],
    separator: "",
    appConfiguration: [{}, {}, {}],
};

const customGridProps = {
    filterPanel: {
        columnsSort: "asc",
        filterFormProps: {
            linkOperatorInputProps: {
                variant: "outlined",
                size: "small",
            },
            columnInputProps: {
                variant: "outlined",
                size: "small",
                sx: { mt: "auto" },
            },
            operatorInputProps: {
                variant: "outlined",
                size: "small",
                sx: { mt: "auto" },
            },
            valueInputProps: {
                InputComponentProps: {
                    variant: "outlined",
                    size: "small",
                },
            },
            deleteIconProps: {
                sx: {
                    "& .MuiSvgIcon-root": { color: "#d32f2f" },
                },
            },
        },
        sx: {
            "& .MuiDataGrid-filterForm": { p: 2 },
            "& .MuiDataGrid-filterFormLinkOperatorInput": { mr: 5 },
            "& .MuiDataGrid-filterFormColumnInput": { mr: 5, width: 150 },
            "& .MuiDataGrid-filterFormOperatorInput": { mr: 5 },
            "& .MuiDataGrid-filterFormValueInput": { width: 200 },
        },
    },
};

const AllBusinessSegments = () => {
    const { state, dispatch } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const [businessSegments, setBusinessSegments] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [chosenRowId, setChosenRowId] = useState(0);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const { t } = useTranslation();

    const [businessSegmentData, setBusinessSegmentData] = useState<IEditBS>();

    useEffect(() => {
        setIsLoading(true);
        api.userDetails
            .getAllBusinessSegments(
                state.user,
                generateToken(instance, accounts)
            )
            .then((res: any) => {
                if (!res) {
                    setIsLoading(false);
                    return;
                }
                setBusinessSegments(res);
                setIsLoading(false);
            });
    }, [state.user]);

    const columns: GridColDef[] = [
        {
            field: "internalId",
            headerName: "Internal ID",
            width: 120,
        },
        {
            field: "externalId",
            headerName: t("label.ExternalId"),
            width: 350,
        },
        {
            field: "countryCode",
            headerName: t("label.locale"),
            width: 150,
        },
        {
            field: "defaultLanguage",
            headerName: t("label.defaultLanguage"),
            width: 150,
        },
        {
            field: "intelexRequired",
            headerName: t("label.IntelexRequired"),
            width: 150,
        },
        {
            field: "isActive",
            headerName: t("label.Active"),
            width: 150,
        },
        {
            field: "",
            headerName: t("label.Edit"),
            sortable: false,
            renderCell: (params: any) => {
                const editBusinessSegmentAction = (e: any) => {
                    /* e.stopPropagation(); */ // don't select this row after clicking

                    const api: GridApi = params.api;
                    const thisRow: Record<string, GridCellValue> = {};

                    api.getAllColumns()
                        .filter((c) => c.field !== "__check__" && !!c)
                        .forEach(
                            (c) =>
                                (thisRow[c.field] = params.getValue(
                                    params.id,
                                    c.field
                                ))
                        );

                    setChosenRowId(
                        parseInt(JSON.stringify(thisRow.internalId, null, 4))
                    );
                };

                return (
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label={t("label.Edit")}
                        onClick={(event: any) => {
                            editBusinessSegmentAction(event);
                            handleOpen();
                        }}
                    />
                );
            },
        },
    ];

    useEffect(() => {
        chosenRowId !== 0 &&
            api.userDetails
                .getBusinessSegmentById(
                    state.user,
                    generateToken(instance, accounts),
                    chosenRowId
                )
                .then((res: any) => {
                    setBusinessSegmentData(res);
                });
    }, [chosenRowId]);

    const handleClose = () => {
        setOpen(false);
        setBusinessSegmentData(defaultValues);
        setChosenRowId(0);
    };

    return (
        <div>
            <Box sx={{ height: "calc(100vh - 220px)", width: "100%" }}>
                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <DataGrid
                        rows={businessSegments}
                        columns={columns}
                        pageSize={50}
                        rowsPerPageOptions={[10]}
                        componentsProps={customGridProps}
                        sx={{ borderStyle: "none" }}
                    />
                )}
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <EditBusinessSegment
                        data={businessSegmentData}
                        handleClose={handleClose}
                    />
                </Box>
            </Modal>
        </div>
    );
};

export default AllBusinessSegments;
