import { ExpandLess } from "@mui/icons-material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse/Collapse";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemButton from "@mui/material/ListItemButton/ListItemButton";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Select from "react-select";
import { useContext, useEffect, useState } from "react";
import { api } from "../../services";
import { UserContext } from "../../context";
import { generateToken } from "../../services/fetch-api/auth/authToken";
import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { Error } from "../../styled";

const defaultValues = {
    userObjectId: "",
    entitlementRequestId: 0,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "0",
};

const SuperAdminManagement = () => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const [openRefresh, setOpenRefresh] = useState(false);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [loading, setIsLoading] = useState(false);

    const [businessSegments, setBusinessSegments] = useState([]);
    const [appIdOrgUnits, setAppIdOrgUnits] = useState(0);
    const [bsIdOrgUnits, setBsIdOrgUnits] = useState(0);
    const [appIdOrgUnitsType, setAppIdOrgUnitsType] = useState(0);
    const [typeId, setTypeId] = useState(0);
    const [appIdRefresh, setAppIdRefresh] = useState(0);
    const [bsRefresh, setBsRefresh] = useState(0);

    const [formValues, setFormValues] = useState(defaultValues);
    const [configTypes, setConfigTypes] = useState([]);
    const [elevateApps, setElevateApps] = useState([]);

    const [refreshConfigLoading, setRefreshConfigLoading] = useState(false);
    const [refreshOrgLoading, setRefreshOrgLoading] = useState(false);
    const [refreshOrgTypeLoading, setRefreshOrgTypeLoading] = useState(false);
    const [notificationsLoading, setNotificationsLoading] = useState(false);
    const [approveLoading, setApproveLoading] = useState(false);

    const customStyles = {
        control: () => ({
            display: "flex",
            width: 315,
            backgroundColor: "white",
            color: "black",
            marginRight: 20,
            border: 1,
            borderStyle: "solid",
            borderColor: "#cdcdcd",
        }),
        option: (provided: any) => ({
            ...provided,
            color: "black",
        }),
        menu: (provided: any) => ({
            ...provided,
            width: 300,
            left: 0,
        }),
    };

    const handleRequiredInput = (event: any) => {
        event.preventDefault();
        if (formValues.userObjectId.trim().length === 0) {
            let errorUserObj: any = document.getElementById("userObjError");
            errorUserObj.style.display = "inline";
        }
        if (formValues.entitlementRequestId === 0) {
            let errorEntReq: any = document.getElementById("entReqError");
            errorEntReq.style.display = "inline";
        }
        if (formValues.firstName.trim().length === 0) {
            let errorName: any = document.getElementById("firstNameError");
            errorName.style.display = "inline";
        }
        if (formValues.lastName.trim().length === 0) {
            let errorLastName: any = document.getElementById("lastNameError");
            errorLastName.style.display = "inline";
        }
        if (formValues.email.trim().length === 0) {
            let errorEmail: any = document.getElementById("emailError");
            errorEmail.style.display = "inline";
        }
    };

    const handleEmptyInputs = () => {
        if (
            formValues.userObjectId === "" ||
            formValues.entitlementRequestId === 0 ||
            formValues.firstName === "" ||
            formValues.lastName === "" ||
            formValues.email === ""
        ) {
            return true;
        } else {
            return false;
        }
    };

    const handleApproveRequestSubmit = (event: any) => {
        if (handleEmptyInputs() === false) {
            event.preventDefault();
            setApproveLoading(true);
            api.entitlement
                .approveEntitlementRequest(
                    state.user,
                    generateToken(instance, accounts),
                    formValues
                )
                .then((res) => {
                    if (res === null) {
                        setApproveLoading(false);
                        return;
                    }
                    setApproveLoading(false);
                });
        } else {
            handleRequiredInput(event);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        api.userDetails
            .getAllBusinessSegments(
                state.user,
                generateToken(instance, accounts)
            )
            .then((res: any) => {
                if (!res) {
                    setIsLoading(false);
                    return;
                }
                setBusinessSegments(
                    res.map((res: any) => {
                        return { value: res.internalId, label: res.externalId };
                    })
                );
                setIsLoading(false);
            });
        api.entitlement
            .getConfigTypes(state.user, generateToken(instance, accounts))
            .then((res: any) => {
                setConfigTypes(res);
            });

        api.entitlement
            .getElevateApplications(
                state.user,
                generateToken(instance, accounts)
            )
            .then((res: any) => {
                setElevateApps(
                    res.map((res: any) => {
                        return { value: res.id, label: res.name };
                    })
                );
            });
    }, [state.user, state.chosenBusinessSegment]);

    const handleRefreshConfig = () => {
        setRefreshConfigLoading(true);
        api.entitlement
            .refreshConfig(
                state.user,
                appIdRefresh,
                bsRefresh,
                generateToken(instance, accounts)
            )
            .then((res: any) => {
                setRefreshConfigLoading(false);
                toast.success("OK");
            });
    };

    const handleCreateOrgUnits = () => {
        setRefreshOrgLoading(true);
        api.entitlement
            .createOrgUnitsAppHierarchies(
                state.user,
                appIdOrgUnits,
                bsIdOrgUnits,
                generateToken(instance, accounts)
            )
            .then((res: any) => {
                setRefreshOrgLoading(false);
                toast.success("OK");
            });
    };

    const handleCreateOrgUnitsByType = () => {
        setRefreshOrgTypeLoading(true);
        api.entitlement
            .createOrgUnitsAppHierarchiesByType(
                state.user,
                appIdOrgUnitsType,
                typeId,
                generateToken(instance, accounts)
            )
            .then((res: any) => {
                setRefreshOrgTypeLoading(false);
                toast.success("OK");
            });
    };

    const handleSendNotifications = () => {
        setNotificationsLoading(true);
        api.entitlement
            .getCreatedInvitesAndSendNotifications(
                state.user,
                generateToken(instance, accounts)
            )
            .then((res: any) => {
                setNotificationsLoading(false);
            });
    };

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        if (name === "entitlementRequestId") {
            setFormValues({
                ...formValues,
                [name]: Number(value),
            });
        } else {
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
    };


    return (
        <>
            <ListItemButton onClick={() => setOpenRefresh(!openRefresh)}>
                <ListItemText primary={t("label.refreshConfig")} />
                {openRefresh ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openRefresh} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem sx={{ pl: 4 }}>
                        <div>
                            <Select
                                options={elevateApps}
                                placeholder={t("label.appId")}
                                styles={customStyles}
                                onChange={(value: any, event: any) =>
                                    setAppIdRefresh(value.value)
                                }
                            />
                        </div>
                        <div>
                            <Select
                                options={businessSegments}
                                styles={customStyles}
                                placeholder={t("label.businessSegmentID")}
                                onChange={(value: any, event: any) =>
                                    setBsRefresh(value.value)
                                }
                            />
                        </div>
                        {refreshConfigLoading ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                disabled={appIdRefresh === 0 || bsRefresh === 0}
                                variant="contained"
                                onClick={handleRefreshConfig}
                            >
                                POST
                            </Button>
                        )}
                    </ListItem>
                </List>
            </Collapse>

            <ListItemButton onClick={() => setOpen(!open)}>
                <ListItemText primary={t("label.refreshOrgUnitsAppHi")} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem sx={{ pl: 4 }}>
                        <div>
                            <Select
                                options={elevateApps}
                                placeholder={t("label.appId")}
                                styles={customStyles}
                                onChange={(value: any, event: any) =>
                                    setAppIdOrgUnits(value.value)
                                }
                            />
                        </div>
                        <div>
                            <Select
                                options={businessSegments}
                                styles={customStyles}
                                placeholder={t("label.businessSegmentID")}
                                onChange={(value: any, event: any) =>
                                    setBsIdOrgUnits(value.value)
                                }
                            />
                        </div>
                        {refreshOrgLoading ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                disabled={
                                    appIdOrgUnits === 0 || bsIdOrgUnits === 0
                                }
                                variant="contained"
                                onClick={handleCreateOrgUnits}
                            >
                                POST
                            </Button>
                        )}
                    </ListItem>
                </List>
            </Collapse>

            <ListItemButton onClick={() => setOpen2(!open2)}>
                <ListItemText primary={t("label.refreshOrgUnitsByType")} />
                {open2 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem sx={{ pl: 4 }}>
                        <div>
                            <Select
                                options={elevateApps}
                                placeholder={t("label.appId")}
                                styles={customStyles}
                                onChange={(value: any, event: any) =>
                                    setAppIdOrgUnitsType(value.value)
                                }
                            />
                        </div>
                        <div>
                            <Select
                                options={configTypes}
                                styles={customStyles}
                                placeholder={t("label.typeId")}
                                onChange={(value: any, event: any) =>
                                    setTypeId(value.value)
                                }
                            />
                        </div>
                        {refreshOrgTypeLoading ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                disabled={
                                    appIdOrgUnitsType === 0 || typeId === 0
                                }
                                variant="contained"
                                onClick={handleCreateOrgUnitsByType}
                            >
                                POST
                            </Button>
                        )}
                    </ListItem>
                </List>
            </Collapse>

            <ListItemButton onClick={() => setOpen3(!open3)}>
                <ListItemText primary={t("label.sendNotifications")} />
                {open3 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open3} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem sx={{ pl: 4 }}>
                        <div>
                            {notificationsLoading ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    variant="contained"
                                    onClick={handleSendNotifications}
                                >
                                    {t("label.sendNotificationsButton")}
                                </Button>
                            )}
                        </div>
                    </ListItem>
                </List>
            </Collapse>

            <ListItemButton onClick={() => setOpen4(!open4)}>
                <ListItemText primary={t("label.approveEntReq")} />
                {open4 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open4} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem sx={{ pl: 4 }}>
                        <div style={{ width: "100%" }}>
                            <Grid
                                container
                                columnSpacing={6}
                                sx={{ marginTop: "15px" }}
                            >
                                <Grid item xs={6} sx={{ marginBottom: "px" }}>
                                    <div>
                                        <InputLabel htmlFor="user-name">
                                            {t("formField.firstName")}
                                            <span style={{ color: "red" }}>
                                                {" "}
                                                *
                                            </span>
                                            <Error
                                                className="errorMsg"
                                                id="firstNameError"
                                            >
                                                {t(
                                                    "errorMessage.RequiredField"
                                                )}
                                            </Error>
                                        </InputLabel>
                                        <Input
                                            fullWidth
                                            id="user-name"
                                            name="firstName"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={6}>
                                    <div>
                                        <InputLabel htmlFor="user-last-name">
                                            {t("formField.lastName")}
                                            <span style={{ color: "red" }}>
                                                {" "}
                                                *
                                            </span>
                                            <Error
                                                className="errorMsg"
                                                id="lastNameError"
                                            >
                                                {t(
                                                    "errorMessage.RequiredField"
                                                )}
                                            </Error>
                                        </InputLabel>
                                        <Input
                                            fullWidth
                                            id="user-last-name"
                                            name="lastName"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                columnSpacing={6}
                                sx={{ marginTop: "15px" }}
                            >
                                <Grid item xs={6} sx={{ marginBottom: "px" }}>
                                    <div>
                                        <InputLabel htmlFor="user-objectid">
                                            {t("label.userObjId")}
                                            <span style={{ color: "red" }}>
                                                {" "}
                                                *
                                            </span>
                                            <Error
                                                className="errorMsg"
                                                id="userObjError"
                                            >
                                                {t(
                                                    "errorMessage.RequiredField"
                                                )}
                                            </Error>
                                        </InputLabel>
                                        <Input
                                            fullWidth
                                            id="user-objectid"
                                            name="userObjectId"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={6}>
                                    <div>
                                        <InputLabel htmlFor="user-entitlement-request">
                                            {t("label.entReqID")}
                                            <span style={{ color: "red" }}>
                                                {" "}
                                                *
                                            </span>
                                            <Error
                                                className="errorMsg"
                                                id="entReqError"
                                            >
                                                {t(
                                                    "errorMessage.RequiredField"
                                                )}
                                            </Error>
                                        </InputLabel>
                                        <Input
                                            fullWidth
                                            id="user-entitlement-request"
                                            name="entitlementRequestId"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                columnSpacing={6}
                                sx={{ marginTop: "15px" }}
                            >
                                <Grid item xs={6} sx={{ marginBottom: "px" }}>
                                    <div>
                                        <InputLabel htmlFor="user-phone-number">
                                            {t("label.phoneNumber")}
                                        </InputLabel>
                                        <Input
                                            fullWidth
                                            id="user-phone"
                                            name="phoneNumber"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={6}>
                                    <div>
                                        <InputLabel htmlFor="user-email">
                                            {t("formField.email")}
                                            <span style={{ color: "red" }}>
                                                {" "}
                                                *
                                            </span>
                                            <Error
                                                className="errorMsg"
                                                id="emailError"
                                            >
                                                {t(
                                                    "errorMessage.RequiredField"
                                                )}
                                            </Error>
                                        </InputLabel>
                                        <Input
                                            fullWidth
                                            id="user-email"
                                            name="email"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                columnSpacing={3}
                                sx={{
                                    marginTop: "15px",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                {approveLoading ? (
                                    <CircularProgress />
                                ) : (
                                    <Button
                                        onClick={handleApproveRequestSubmit}
                                        variant={"contained"}
                                    >
                                        {t("formField.submit")}
                                    </Button>
                                )}
                            </Grid>
                        </div>
                    </ListItem>
                </List>
            </Collapse>
        </>
    );
};

export default SuperAdminManagement;
