import { useMsal } from "@azure/msal-react";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context";
import { api } from "../../services";
import { generateToken } from "../../services/fetch-api/auth/authToken";
import { InvitationErrorLog } from "../../types";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, LinearProgress, styled, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import moment from "moment";
import jstz from "jstz";

const DataGridStyled = styled(DataGrid)(() => ({
    /*    '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }*/
}));

function getLocalizedTime(timeZone: any, time: any) {
    const options: any = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
        timeZone: timeZone,
    };

    return new Intl.DateTimeFormat("en-US", options).format(time);
}

const Audit = () => {
    const { t } = useTranslation();
    const { instance, accounts } = useMsal();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [items, setItems] = useState<InvitationErrorLog[]>([]);
    const { state, dispatch } = useContext(UserContext);
    const [errors, setErrors] = useState([]);
    const timezone = jstz.determine();
    const timezoneName = timezone.name();

    const [invitationId, setInvitationId] = useState<number>();

    const columns: GridColDef[] = [
        {
            field: "rowNumber",
            headerName: t("formField.rowNumber"),
            width: 100,
            resizable: true,
        },
        {
            field: "createdAt",
            headerName: t("formField.createdAt"),
            width: 150,
            resizable: true,
            renderCell: (params: any) =>
                getLocalizedTime(
                    timezoneName,
                    Date.parse(params.formattedValue)
                ).toString(),
        },
        {
            field: "errorContent",
            headerName: t("formField.errorContent"),
            width: 900,
            renderCell: (params: any) => (
                <Tooltip title={params.formattedValue}>
                    <span className="table-cell-trucate">
                        {params.formattedValue}
                    </span>
                </Tooltip>
            ),
        },
    ];

    const localizedGridTextsMap = {
        columnMenuUnsort: t("dataGrid.columnMenuUnsort"),
        columnMenuSortAsc: t("dataGrid.columnMenuSortAsc"),
        columnMenuSortDesc: t("dataGrid.columnMenuSortDesc"),
        columnMenuFilter: t("dataGrid.columnMenuFilter"),
        columnMenuHideColumn: t("dataGrid.columnMenuHideColumn"),
        columnMenuShowColumns: t("dataGrid.columnMenuShowColumns"),
    };

    const handleChange = (event: any) => {
        setInvitationId(parseInt(event.value));
    };

    useEffect(() => {
        if (!state.user || !instance || !accounts) return;
        setIsLoading(true);
        invitationId !== undefined &&
            api.useInvitationLogs
                .getInvitationLogErrors(
                    state.user,
                    invitationId,
                    generateToken(instance, accounts)
                )
                .then((res: any) => {
                    if (!res) return;
                    setErrors(res);
                    setIsLoading(false);
                });
    }, [invitationId]);

    useEffect(() => {
        if (
            !state.user ||
            !instance ||
            !accounts ||
            !state.chosenBusinessSegment ||
            !state.appId
        ) {
            setIsLoading(false);
            return;
        } else {
            setIsLoading(true);
        }
        api.useInvitationLogs
            .getInvitationLogs(
                state.user,
                state.appId,
                state.chosenBusinessSegment.internalId,
                generateToken(instance, accounts)
            )
            ?.then((res: any) => {
                if (!res) {
                    setIsLoading(false);
                    return;
                }
                setItems(res);
                setIsLoading(false);
            });
    }, [
        instance,
        accounts,
        state.user,
        state.appId,
        state.chosenBusinessSegment,
    ]);

    const options: { label: string; value: any }[] = [];

    state.user &&
        state.user?.firstName &&
        state.user?.lastName &&
        items.forEach((data: any) => {
            options.push({
                value: data.id,
                label:
                    data.fileName +
                    " - " +
                    moment(data.createdAt).format("YYYY/MM/DD HH:mm"),
            });
        });

    return (
        <div>
            {options.length > 0 ? (
                <div>
                    <Select
                        options={options}
                        onChange={handleChange}
                        placeholder={t("formField.chooseOption")}
                    />
                </div>
            ) : (
                <Box sx={{ width: "100%" }}>
                    {isLoading && <LinearProgress />}
                </Box>
            )}
            {errors.length > 0 ? (
                <Box sx={{ height: "calc(100vh - 160px)", width: "100%" }}>
                    <DataGridStyled
                        rows={errors}
                        columns={columns}
                        pageSize={50}
                        rowsPerPageOptions={[10]}
                        loading={isLoading}
                        localeText={localizedGridTextsMap}
                    />
                </Box>
            ) : null}
        </div>
    );
};

export default Audit;
