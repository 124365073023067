import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context";
import { Box } from "@mui/system";
import {
    Button,
    Grid,
    Input,
    InputLabel,
    NativeSelect,
    Checkbox,
    FormControlLabel,
    FormControl,
    CircularProgress,
    Typography,
} from "@mui/material";
import { IEntitlementRequest } from "../../types";
import { api } from "../../services";
import { generateToken } from "../../services/fetch-api/auth/authToken";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SaveIcon from "@mui/icons-material/Save";
import { countries } from "../../constants";
import LinearProgress from "@mui/material/LinearProgress";
import styled from "@emotion/styled";
import elevateImg from "../../assets/elevate.png";
import lidapImg from "../../assets/lidap.png";
import trainingImg from "../../assets/training.png";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Buffer } from 'buffer';

const LidapCard = styled("div")(({ theme }) => ({
    width: "200px",
    height: "100px",
    backgroundColor: "white",
    boxShadow: "5px 5px 12px -6px rgba(0, 0, 0, 1)",
    padding: "20px",
    marginLeft: "20px",
    cursor: "pointer",
    borderRadius: "8px",
    backgroundImage: `url(${lidapImg})`,
    backgroundPosition: "25% 75%",
    backgroundSize: "cover",
}));

const TrainingCard = styled("div")(({ theme }) => ({
    width: "200px",
    height: "100px",
    backgroundColor: "white",
    boxShadow: "5px 5px 12px -6px rgba(0, 0, 0, 1)",
    padding: "20px",
    marginLeft: "20px",
    cursor: "pointer",
    borderRadius: "8px",
    backgroundImage: `url(${trainingImg})`,
    backgroundPosition: "25% 75%",
    backgroundSize: "cover",
}));

const ElevateCard = styled("div")(({ theme }) => ({
    width: "200px",
    height: "100px",
    backgroundColor: "white",
    boxShadow: "5px 5px 12px -6px rgba(0, 0, 0, 1)",
    padding: "20px",
    marginLeft: "20px",
    cursor: "pointer",
    borderRadius: "8px",
    backgroundImage: `url(${elevateImg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
}));

const LinxCard = styled("div")(({ theme }) => ({
    width: "200px",
    height: "100px",
    backgroundColor: "#780000",
    boxShadow: "5px 5px 12px -6px rgba(0, 0, 0, 1)",
    padding: "20px",
    marginLeft: "20px",
    cursor: "pointer",
    borderRadius: "8px",
}));

const InputContainer = styled("div")(() => ({
    marginBottom: "10px",
}));

const ConsentLabel = styled("p")(() => ({
    fontSize: "14px",
}));

const Required = styled("p")(() => ({
    fontSize: "12px",
    color: "red",
    margin: 0,
}));

const UserDetail = () => {
    const { t } = useTranslation();
    const { state } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const [entitlementRequest, setEntitlementRequest] =
        useState<IEntitlementRequest | null>(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [acceptAgeRestriction, setAcceptAgeRestriction] =
        useState<boolean>(false);
    const [acceptLegalConsent, setAcceptLegalConsent] =
        useState<boolean>(false);
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    const [isProceedLoading, setIsProceedLoading] = useState(false);
    const urlHash = window.location.hash;
    const [linxInviteeLoading, setLinxInviteeLoading] = useState(false);


    const handleSave = () => {
        setFormSubmitted(true);

        if (
            !entitlementRequest ||
            (entitlementRequest?.entitlementRequestConfiguration[
                entitlementRequest?.cornerstoneConfigurationConstants[
                    "AgeRestrictionRequired"
                ].toString()
            ] === "Yes" &&
                !acceptAgeRestriction) ||
            (entitlementRequest?.entitlementRequestConfiguration[
                entitlementRequest?.cornerstoneConfigurationConstants[
                    "LegalConsentRequired"
                ].toString()
            ] === "Yes" &&
                !acceptLegalConsent)
        )
            return;
        setIsProceedLoading(true);
        api.userDetails
            .approveRequest(
                state.user,
                entitlementRequest,
                generateToken(instance, accounts)
            )
            .then((res) => {
                if (res === null) {
                    setIsProceedLoading(false);
                    return;
                }
                setIsProceedLoading(false);
                toast.success("OK");
                if (!entitlementRequest) return;
                setEntitlementRequest({
                    ...entitlementRequest,
                    userObjectID: "test",
                });
            });
    };

    const handleAcceptAgeRestriction = (event: any) => {
        setAcceptAgeRestriction(event.target.checked);
    };

    const handleAcceptLegalConsent = (event: any) => {
        setAcceptLegalConsent(event.target.checked);
    };

    useEffect(() => {
        if (!state.user) return;
        setIsLoading(true);
        idTokenClaims &&
            idTokenClaims.acr !== "b2c_1a_v1_linde_linx_signup_email_invite" &&
            api.entitlement
                .getEntitlementRequestById(
                    Number(window.localStorage.getItem("entitlementRequestId")),
                    state.user,
                    generateToken(instance, accounts)
                )
                .then((res: any) => {
                    setIsLoading(false);
                    if (!res) return;
                    setEntitlementRequest(res);
                });
    }, [state.user]);

    const hash = window.location.hash.replace('#id_token=', '');
    const idTokenClaims = hash ? JSON.parse(Buffer.from(hash.split('.')[1], 'base64').toString('utf-8')) : '';

    console.log(idTokenClaims);

    useEffect(() => {
        if (
            idTokenClaims &&
            idTokenClaims.acr === "b2c_1a_v1_linde_linx_signup_email_invite"
        ) {
            setLinxInviteeLoading(true);
            api.linx
                .approveLinxEntitlementRequest(
                    state.user,
                    {
                        userObjectId: idTokenClaims.sub,
                        entitlementRequestId: Number(
                            idTokenClaims.entitlementRequestId
                        ),
                        firstName: idTokenClaims.first_name,
                        lastName: idTokenClaims.family_name,
                        email: idTokenClaims.email,
                        phoneNumber: "",
                    },
                    generateToken(instance, accounts)
                )
                .then((res: any) => {
                    navigate("/");
                    setLinxInviteeLoading(false);
                });
        }
    }, [state.user]);

    return (
        <>
            {idTokenClaims &&
                idTokenClaims.acr.includes("admin_passwordreset") && (
                    <p
                        style={{
                            color: "#1976d2",
                            fontSize: "18px",
                            fontWeight: "700",
                            textAlign: "center",
                            marginTop: "70px",
                        }}
                    >
                        {t("label.passChangeMessage")}
                    </p>
                )}

            {idTokenClaims &&
                idTokenClaims.acr === "b2c_1a_v1_linde_linx_signup_email_invite" ? (
                linxInviteeLoading ? (
                    <LinearProgress />
                ) : (
                    <a href="https://www.google.com" target="_blank">
                        <LinxCard />
                    </a>
                )
            ) : idTokenClaims &&
                idTokenClaims.acr ===
                "b2c_1a_v1_linde_portal_user_change_signinemail" ? (
                <p
                    style={{
                        color: "#1976d2",
                        fontSize: "18px",
                        fontWeight: "700",
                        textAlign: "center",
                        marginTop: "70px",
                    }}
                >
                    {t("label.emailChangeMessage")}
                </p>
            ) : (
                <>
                    {!urlHash.includes("AADB2C90208") ? (
                        <Box sx={{ margin: "20px" }}>
                            <div>
                                {state.user && state.user.firstName && (
                                    <h3>{t("form.myDetails.title")}</h3>
                                )}
                                {isLoading &&
                                    state.user &&
                                    state.user.firstName && (
                                        <>
                                            <LinearProgress />
                                            <p
                                                style={{
                                                    fontSize: "17px",
                                                    fontWeight: 700,
                                                }}
                                            >
                                                Loading page. This might take a
                                                moment.
                                            </p>
                                        </>
                                    )}
                            </div>

                            {!isLoading && (
                                <>
                                    <Grid
                                        container
                                        columnSpacing={4}
                                        sx={{ marginTop: "15px" }}
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{ marginBottom: "px" }}
                                        >
                                            <InputContainer>
                                                <InputLabel htmlFor="user-name">
                                                    {t("formField.firstName")}
                                                </InputLabel>
                                                <Input
                                                    fullWidth
                                                    readOnly
                                                    id="user-name"
                                                    value={
                                                        state.user?.firstName
                                                    }
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <InputLabel htmlFor="user-email">
                                                    {t("formField.email")}
                                                </InputLabel>
                                                <Input
                                                    fullWidth
                                                    readOnly
                                                    id="user-email"
                                                    value={
                                                        state.user?.email === ""
                                                            ? entitlementRequest?.email
                                                            : state.user?.email
                                                    }
                                                />
                                            </InputContainer>
                                            {(entitlementRequest?.appInfoID ===
                                                1 ||
                                                entitlementRequest?.appInfoID ===
                                                8) && (
                                                    <>
                                                        <InputContainer>
                                                            <InputLabel htmlFor="user-country">
                                                                {t(
                                                                    "formField.CountryCode"
                                                                )}
                                                            </InputLabel>
                                                            <NativeSelect
                                                                fullWidth
                                                                disabled
                                                                id="user-country"
                                                                value={
                                                                    entitlementRequest?.countryCode
                                                                }
                                                            >
                                                                {countries.map(
                                                                    (c, index) => (
                                                                        <option
                                                                            key={
                                                                                index
                                                                            }
                                                                            value={
                                                                                c.value
                                                                            }
                                                                        >
                                                                            {
                                                                                c.label
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </NativeSelect>
                                                        </InputContainer>
                                                    </>
                                                )}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{ marginBottom: "px" }}
                                        >
                                            <InputContainer>
                                                <InputLabel htmlFor="user-last-name">
                                                    {t("formField.lastName")}
                                                </InputLabel>
                                                <Input
                                                    fullWidth
                                                    readOnly
                                                    id="user-last-name"
                                                    value={state.user?.lastName}
                                                />
                                            </InputContainer>
                                            <InputContainer>
                                                <InputLabel htmlFor="user-phone">
                                                    {t("formField.telephone")}
                                                </InputLabel>
                                                <Input
                                                    fullWidth
                                                    readOnly
                                                    id="user-phone"
                                                    value={
                                                        state.user?.phone === ""
                                                            ? entitlementRequest?.phoneNbr
                                                            : state.user?.phone
                                                    }
                                                />
                                            </InputContainer>
                                        </Grid>
                                    </Grid>
                                    {entitlementRequest?.appInfoID === 1 &&
                                        entitlementRequest
                                            ?.entitlementRequestConfiguration[
                                        entitlementRequest?.cornerstoneConfigurationConstants[
                                            "Intelexrequired"
                                        ].toString()
                                        ] === "Yes" && (
                                            <>
                                                <Grid
                                                    container
                                                    columnSpacing={4}
                                                    sx={{ marginTop: "15px" }}
                                                >
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sx={{
                                                            marginBottom: "px",
                                                        }}
                                                    >
                                                        <InputContainer
                                                            hidden={
                                                                entitlementRequest
                                                                    ?.entitlementRequestConfiguration[
                                                                entitlementRequest?.cornerstoneConfigurationConstants[
                                                                    "CornerstoneData1Label"
                                                                ].toString()
                                                                ] === ""
                                                            }
                                                        >
                                                            <InputLabel htmlFor="user-cornerstone1">
                                                                {
                                                                    entitlementRequest
                                                                        ?.entitlementRequestConfiguration[
                                                                    entitlementRequest?.cornerstoneConfigurationConstants[
                                                                        "CornerstoneData1Label"
                                                                    ].toString()
                                                                    ]
                                                                }
                                                            </InputLabel>
                                                            <Input
                                                                fullWidth
                                                                readOnly
                                                                id="user-cornerstone1"
                                                                value={
                                                                    entitlementRequest?.cornerstoneData1
                                                                }
                                                            />
                                                        </InputContainer>
                                                        <InputContainer
                                                            hidden={
                                                                entitlementRequest
                                                                    ?.entitlementRequestConfiguration[
                                                                entitlementRequest?.cornerstoneConfigurationConstants[
                                                                    "CornerstoneData3Label"
                                                                ].toString()
                                                                ] === ""
                                                            }
                                                        >
                                                            <InputLabel htmlFor="user-cornerstone3">
                                                                {
                                                                    entitlementRequest
                                                                        ?.entitlementRequestConfiguration[
                                                                    entitlementRequest?.cornerstoneConfigurationConstants[
                                                                        "CornerstoneData3Label"
                                                                    ].toString()
                                                                    ]
                                                                }
                                                            </InputLabel>
                                                            <Input
                                                                fullWidth
                                                                readOnly
                                                                id="user-cornerstone3"
                                                                value={
                                                                    entitlementRequest?.cornerstoneData3
                                                                }
                                                            />
                                                        </InputContainer>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sx={{
                                                            marginBottom: "px",
                                                        }}
                                                    >
                                                        <InputContainer
                                                            hidden={
                                                                entitlementRequest
                                                                    ?.entitlementRequestConfiguration[
                                                                entitlementRequest?.cornerstoneConfigurationConstants[
                                                                    "CornerstoneData2Label"
                                                                ].toString()
                                                                ] === ""
                                                            }
                                                        >
                                                            <InputLabel htmlFor="user-cornerstone2">
                                                                {
                                                                    entitlementRequest
                                                                        ?.entitlementRequestConfiguration[
                                                                    entitlementRequest?.cornerstoneConfigurationConstants[
                                                                        "CornerstoneData2Label"
                                                                    ].toString()
                                                                    ]
                                                                }
                                                            </InputLabel>
                                                            <Input
                                                                fullWidth
                                                                readOnly
                                                                id="user-cornerstone2"
                                                                value={
                                                                    entitlementRequest?.cornerstoneData2
                                                                }
                                                            />
                                                        </InputContainer>
                                                        <InputContainer
                                                            hidden={
                                                                entitlementRequest
                                                                    ?.entitlementRequestConfiguration[
                                                                entitlementRequest?.cornerstoneConfigurationConstants[
                                                                    "CornerstoneData4Label"
                                                                ].toString()
                                                                ] === ""
                                                            }
                                                        >
                                                            <InputLabel htmlFor="user-cornerstone4">
                                                                {
                                                                    entitlementRequest
                                                                        ?.entitlementRequestConfiguration[
                                                                    entitlementRequest?.cornerstoneConfigurationConstants[
                                                                        "CornerstoneData4Label"
                                                                    ].toString()
                                                                    ]
                                                                }
                                                            </InputLabel>
                                                            <Input
                                                                fullWidth
                                                                readOnly
                                                                id="user-cornerstone4"
                                                                value={
                                                                    entitlementRequest?.cornerstoneData4
                                                                }
                                                            />
                                                        </InputContainer>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                    {(entitlementRequest?.appInfoID === 3 ||
                                        (entitlementRequest?.appInfoID === 1 &&
                                            entitlementRequest
                                                ?.entitlementRequestConfiguration[
                                            entitlementRequest?.cornerstoneConfigurationConstants[
                                                "Intelexrequired"
                                            ].toString()
                                            ] === "Yes")) && (
                                            <>
                                                <Grid
                                                    container
                                                    columnSpacing={4}
                                                    sx={{ marginTop: "15px" }}
                                                >
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sx={{ marginBottom: "px" }}
                                                    >
                                                        <InputContainer>
                                                            <InputLabel htmlFor="user-name">
                                                                {t(
                                                                    "formField.Location"
                                                                )}
                                                            </InputLabel>
                                                            <Input
                                                                fullWidth
                                                                readOnly
                                                                id="user-name"
                                                                value={entitlementRequest?.intelexLocationObject?.name.concat(
                                                                    " - ",
                                                                    entitlementRequest
                                                                        ?.intelexLocationObject
                                                                        ?.code
                                                                )}
                                                            />
                                                        </InputContainer>
                                                        <InputContainer>
                                                            <InputLabel htmlFor="user-name">
                                                                {t(
                                                                    "formField.SupervisorNumber"
                                                                )}
                                                            </InputLabel>
                                                            <Input
                                                                fullWidth
                                                                readOnly
                                                                id="user-name"
                                                                value={
                                                                    entitlementRequest?.supervisorDisplayName
                                                                }
                                                            />
                                                        </InputContainer>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sx={{ marginBottom: "px" }}
                                                    >
                                                        <InputContainer>
                                                            <InputLabel htmlFor="user-name">
                                                                {t(
                                                                    "formField.PositionTitle"
                                                                )}
                                                            </InputLabel>
                                                            <Input
                                                                fullWidth
                                                                readOnly
                                                                id="user-name"
                                                                value={
                                                                    entitlementRequest?.positionTitle
                                                                }
                                                            />
                                                        </InputContainer>
                                                        <InputContainer>
                                                            <InputLabel htmlFor="user-name">
                                                                {t(
                                                                    "formField.myCompany"
                                                                )}
                                                            </InputLabel>
                                                            <Input
                                                                fullWidth
                                                                readOnly
                                                                id="user-name"
                                                                value={
                                                                    entitlementRequest?.company
                                                                }
                                                            />
                                                        </InputContainer>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}

                                    {(entitlementRequest?.appInfoID === 1 ||
                                        entitlementRequest?.appInfoID ===
                                        3) && (
                                            <>
                                                {entitlementRequest?.entitlementRequestConfiguration &&
                                                    entitlementRequest
                                                        ?.entitlementRequestConfiguration[
                                                    entitlementRequest?.cornerstoneConfigurationConstants[
                                                        "AgeRestrictionRequired"
                                                    ].toString()
                                                    ] === "Yes" && (
                                                        <>
                                                            <Grid
                                                                container
                                                                columnSpacing={4}
                                                                sx={{
                                                                    marginLeft:
                                                                        "0px",
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            defaultChecked={
                                                                                acceptAgeRestriction
                                                                            }
                                                                            onChange={
                                                                                handleAcceptAgeRestriction
                                                                            }
                                                                        />
                                                                    }
                                                                    label={
                                                                        <ConsentLabel>
                                                                            {
                                                                                entitlementRequest
                                                                                    ?.entitlementRequestConfiguration[
                                                                                entitlementRequest?.cornerstoneConfigurationConstants[
                                                                                    "AgeRestrictionText"
                                                                                ].toString()
                                                                                ]
                                                                            }
                                                                            <span
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                *
                                                                            </span>
                                                                        </ConsentLabel>
                                                                    }
                                                                />
                                                            </Grid>
                                                            {formSubmitted &&
                                                                !acceptAgeRestriction ? (
                                                                <Required>
                                                                    {t(
                                                                        "errorMessage.RequiredField"
                                                                    )}
                                                                </Required>
                                                            ) : null}
                                                        </>
                                                    )}

                                                {entitlementRequest?.entitlementRequestConfiguration &&
                                                    entitlementRequest
                                                        ?.entitlementRequestConfiguration[
                                                    entitlementRequest?.cornerstoneConfigurationConstants[
                                                        "LegalConsentRequired"
                                                    ].toString()
                                                    ] === "Yes" && (
                                                        <>
                                                            <Grid
                                                                container
                                                                columnSpacing={4}
                                                                sx={{
                                                                    marginLeft:
                                                                        "0px",
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            defaultChecked={
                                                                                acceptLegalConsent
                                                                            }
                                                                            onChange={
                                                                                handleAcceptLegalConsent
                                                                            }
                                                                        />
                                                                    }
                                                                    label={
                                                                        <ConsentLabel>
                                                                            {
                                                                                entitlementRequest
                                                                                    ?.entitlementRequestConfiguration[
                                                                                entitlementRequest?.cornerstoneConfigurationConstants[
                                                                                    "LegalConsentText"
                                                                                ].toString()
                                                                                ]
                                                                            }
                                                                            <span
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                *
                                                                            </span>
                                                                        </ConsentLabel>
                                                                    }
                                                                />
                                                            </Grid>
                                                            {formSubmitted &&
                                                                !acceptLegalConsent ? (
                                                                <Required>
                                                                    {t(
                                                                        "ferrorMessage.RequiredField"
                                                                    )}
                                                                </Required>
                                                            ) : null}
                                                        </>
                                                    )}
                                            </>
                                        )}

                                    {!entitlementRequest?.userObjectID ? (
                                        <Grid
                                            container
                                            columnSpacing={4}
                                            sx={{ marginTop: "10px" }}
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sx={{ textAlign: "right" }}
                                            >
                                                {entitlementRequest?.statusName ===
                                                    "Rejected" ? (
                                                    <Button
                                                        disabled
                                                        startIcon={<SaveIcon />}
                                                        variant="outlined"
                                                    >
                                                        {t(
                                                            "formField.requestRejected"
                                                        )}
                                                    </Button>
                                                ) : !isProceedLoading ? (
                                                    <Button
                                                        startIcon={<SaveIcon />}
                                                        onClick={handleSave}
                                                        variant="outlined"
                                                    >
                                                        {t("formField.proceed")}
                                                    </Button>
                                                ) : (
                                                    <CircularProgress />
                                                )}
                                            </Grid>
                                            <Grid item xs={6}>
                                                {/*                                  <Button variant="outlined" startIcon={<CancelPresentationIcon />} onClick={() => navigate('/')}>{t('formField.cancel')}</Button>*/}
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                            sx={{ margin: "20px 0" }}
                                        >
                                            {(entitlementRequest?.appInfoID ===
                                                1 ||
                                                entitlementRequest?.appInfoID ===
                                                8) && (
                                                    <a
                                                        href={
                                                            entitlementRequest.appUrl
                                                        }
                                                    >
                                                        <ElevateCard />
                                                    </a>
                                                )}
                                            {entitlementRequest?.appInfoID ===
                                                3 &&
                                                entitlementRequest?.statusId ===
                                                1 && (
                                                    <a
                                                        href={
                                                            entitlementRequest.appUrl
                                                        }
                                                    >
                                                        <LidapCard />
                                                    </a>
                                                )}
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Box>
                    ) : (
                        <div
                            style={{
                                width: "fit-content",
                                margin: "auto",
                                marginTop: "100px",
                            }}
                        >
                            <p
                                style={{
                                    color: "#1976d2",
                                    fontSize: "18px",
                                    fontWeight: "700",
                                }}
                            >
                                {t("label.linkExpiredFirstLine")}
                            </p>
                            <p
                                style={{
                                    color: "#1976d2",
                                    fontSize: "18px",
                                    fontWeight: "700",
                                }}
                            >
                                {t("label.linkExpiredSecondLine")}
                            </p>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default UserDetail;
