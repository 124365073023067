import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Autocomplete,
    Box,
    Grid,
    Input,
    InputLabel,
    LinearProgress,
    styled,
    TextField,
} from "@mui/material";
import { InputContainer, ManagersData } from "../../styled";

const Error = styled("span")(() => ({
    color: "red",
    fontSize: "10px",
    margin: 0,
    marginLeft: "5px",
    marginBottom: "2px",
}));

const AdHocManagersFields = (props: any) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        props.setManagersSearchValues({
            ...props.managersSearchValues,
            [name]: value,
        });
    };


    useEffect(() => {
        if (
            props.managersSearchValues.lastName !== "" ||
            props.managersSearchValues.firstName !== "" ||
            props.managersSearchValues.email !== "" ||
            props.managersSearchValues.user_ref !== "" ||
            props.managersSearchValues.cornerstoneID !== ""
        ) {
            setOpen(true);
        }
    }, [props.lindeManagers]);

    return (
        <Box sx={{ width: "calc(100vh-30px)" }}>
            <ManagersData>
                <legend>{t("label.searchManagers")}</legend>
                <Grid container columnSpacing={6} sx={{ marginTop: "15px" }}>
                    <Grid item xs={6} sx={{ marginBottom: "px" }}>
                        <InputContainer>
                            <InputLabel htmlFor="user-name">
                                {t("formField.firstName")}
                            </InputLabel>
                            <Input
                                fullWidth
                                id="user-name"
                                name="firstName"
                                onChange={handleInputChange}
                            />
                        </InputContainer>
                    </Grid>
                    <Grid item xs={6} sx={{ position: "relative" }}>
                        {props.lindeManagersLoading && (
                            <LinearProgress
                                sx={{
                                    position: "absolute",
                                    left: 48,
                                    right: 0,
                                    bottom: 10,
                                }}
                            />
                        )}
                        <InputContainer>
                            <InputLabel htmlFor="country-code">
                                {t("label.searchResults")}
                                {props.mandatory && (
                                    <span style={{ color: "red" }}> *</span>
                                )}
                                {props.mandatoryOnSubmit && (
                                    <Error className="errorMsgs">
                                        {t("errorMessage.RequiredField")}
                                    </Error>
                                )}
                            </InputLabel>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                options={props.lindeManagers}
                                sx={{ width: "100%" }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" />
                                )}
                                onChange={(event: any, value: any) =>
                                    props.handleAutocompleteChange(
                                        value.id,
                                        "lindeManagerCPIDExternal"
                                    )
                                }
                            />
                        </InputContainer>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={6} sx={{ marginTop: "15px" }}>
                    <Grid item xs={6}>
                        <InputContainer>
                            <InputLabel htmlFor="user-last-name">
                                {t("formField.lastName")}
                            </InputLabel>
                            <Input
                                fullWidth
                                id="user-last-name"
                                name="lastName"
                                onChange={handleInputChange}
                            />
                        </InputContainer>
                    </Grid>
                </Grid>

                <Grid container columnSpacing={6} sx={{ marginTop: "15px" }}>
                    <Grid item xs={6} sx={{ marginBottom: "px" }}>
                        <InputContainer>
                            <InputLabel htmlFor="user-email">
                                {t("formField.email")}
                            </InputLabel>
                            <Input
                                fullWidth
                                id="user-email"
                                name="email"
                                onChange={handleInputChange}
                            />
                        </InputContainer>
                    </Grid>
                </Grid>

                <Grid container columnSpacing={6} sx={{ marginTop: "15px" }}>
                    <Grid item xs={6}>
                        <InputContainer>
                            <InputLabel htmlFor="user-ref">
                                CPID / B2C ObjectId
                            </InputLabel>
                            <Input
                                fullWidth
                                id="user-ref"
                                name="user_ref"
                                onChange={handleInputChange}
                            />
                        </InputContainer>
                    </Grid>
                </Grid>

                <Grid container columnSpacing={6} sx={{ marginTop: "15px" }}>
                    <Grid item xs={6} sx={{ marginBottom: "px" }}>
                        <InputContainer>
                            <InputLabel htmlFor="user-id">
                                Linde User ID
                            </InputLabel>
                            <Input
                                fullWidth
                                id="user-id"
                                name="cornerstoneID"
                                onChange={handleInputChange}
                            />
                        </InputContainer>
                    </Grid>
                </Grid>
            </ManagersData>
        </Box>
    );
};

export default AdHocManagersFields;
