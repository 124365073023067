export const getLocaleKey = (browserLocale: any) => {
    let language: string;
    if (localStorage.getItem('i18nextLng') !== null) {
        language = localStorage.getItem('i18nextLng')!!.slice(0, 2)
    } else {
        language = browserLocale.slice(0, 2);
    }
    return language
}


export const handleRequiredInput = (
    event: any,
    mandatoryFieldsProp: any,
    formValuesProp: any,
    setManagersMandatoryOnSubmit: (value: boolean) => void,
    setLindeManagerRequired: (value: boolean) => void,
    state: any
) => {
    event.preventDefault();
    if (mandatoryFieldsProp && mandatoryFieldsProp.FirstName === true) {
        if (formValuesProp.firstName.trim().length === 0) {
            let errorName: any = document.getElementById("FirstNameError");
            errorName.style.display = "inline";
        }
    }
    if (mandatoryFieldsProp && mandatoryFieldsProp.LastName === true) {
        if (formValuesProp.lastName.trim().length === 0) {
            let errorLast: any = document.getElementById("LastNameError");
            errorLast.style.display = "inline";
        }
    }
    if (mandatoryFieldsProp && mandatoryFieldsProp.Email === true) {
        if (formValuesProp.email.trim().length === 0) {
            let errorEmail: any = document.getElementById("EmailError");
            errorEmail.style.display = "inline";
        }
    }
    if (mandatoryFieldsProp && mandatoryFieldsProp.LocationExternalId === true) {
        if (formValuesProp.locationExternalId === 0) {
            let errorLocation: any =
                document.getElementById("LocationError");
            errorLocation.style.display = "inline";
        }
    }
    if (mandatoryFieldsProp && mandatoryFieldsProp.SupervisorNumber === true) {
        if (state.appId === 1) {
            if (formValuesProp.supervisorNumber.trim().length === 0) {
                let errorSupervisor: any =
                    document.getElementById("SupervisorError");
                errorSupervisor.style.display = "inline";
            }
        }
    }
    if (mandatoryFieldsProp && mandatoryFieldsProp.PhoneNbr === true) {
        if (formValuesProp.phoneNbr === "") {
            let errorPhone: any = document.getElementById("PhoneError");
            errorPhone.style.display = "inline";
        }
    }

    if (mandatoryFieldsProp && mandatoryFieldsProp.CountryCode === true) {
        if (formValuesProp.countryCode.trim().length === 0) {
            let errorName: any =
                document.getElementById("CountryCodeError");
            errorName.style.display = "inline";
        }
    }
    if (
        mandatoryFieldsProp &&
        mandatoryFieldsProp.SubAuthorisationExternalId === true
    ) {
        if (formValuesProp.subAuthorisationExternalId === 0) {
            let errorName: any = document.getElementById("SubAuthError");
            errorName.style.display = "inline";
        }
    }
    if (mandatoryFieldsProp && mandatoryFieldsProp.LindeManagerCPID === true) {
        if (formValuesProp.lindeManagerCPIDExternal.trim().length === 0) {
            setManagersMandatoryOnSubmit(true);
        }
    }
    if (
        mandatoryFieldsProp &&
        mandatoryFieldsProp.ExternalPositionExternalId === true
    ) {
        if (formValuesProp.externalPositionExternalId === 0) {
            let errorName: any = document.getElementById(
                "ExternalPositionError"
            );
            errorName.style.display = "inline";
        }
    }
    if (mandatoryFieldsProp && mandatoryFieldsProp.LegalEntityInternalId === true) {
        if (formValuesProp.legalEntityInternalId === 0) {
            let errorName: any =
                document.getElementById("LegalEntityError");
            errorName.style.display = "inline";
        }
    }
    if (mandatoryFieldsProp && mandatoryFieldsProp.BusinessRelationship === true) {
        if (formValuesProp.businessRelationshipExternalId === "") {
            let errorName: any = document.getElementById(
                "BusinessRelationshipError"
            );
            errorName.style.display = "inline";
        }
    }
    if (mandatoryFieldsProp && mandatoryFieldsProp.IntelexLocationCode === true) {
        if (formValuesProp.locationCode.trim().length === 0) {
            let errorName: any = document.getElementById(
                "IntelexLocationError"
            );
            errorName.style.display = "inline";
        }
    }
    if (mandatoryFieldsProp && mandatoryFieldsProp.Language === true) {
        if (formValuesProp.language.trim().length === 0) {
            let errorName: any = document.getElementById("LanguageError");
            errorName.style.display = "inline";
        }
    }
    if (mandatoryFieldsProp && mandatoryFieldsProp.LindeManagerCPID === true) {
        if (formValuesProp.lindeManagerCPIDExternal.trim().length === 0) {
            setLindeManagerRequired(true);
        }
    }
};

export const handleEmptyInputs = (mandatoryFieldsProp: any, formValuesProp: any) => {
    if (
        (mandatoryFieldsProp &&
            mandatoryFieldsProp.LindeManagerCPID &&
            formValuesProp.lindeManagerCPIDExternal === "") ||
        (mandatoryFieldsProp &&
            mandatoryFieldsProp.FirstName &&
            formValuesProp.firstName === "") ||
        (mandatoryFieldsProp &&
            mandatoryFieldsProp.LastName &&
            formValuesProp.lastName === "") ||
        (mandatoryFieldsProp &&
            mandatoryFieldsProp.CountryCode &&
            formValuesProp.countryCode === "") ||
        (mandatoryFieldsProp &&
            mandatoryFieldsProp.Email &&
            formValuesProp.email === "") ||
        (mandatoryFieldsProp &&
            mandatoryFieldsProp.LocationExternalId &&
            formValuesProp.locationExternalId === "") ||
        (mandatoryFieldsProp &&
            mandatoryFieldsProp.PhoneNbr &&
            formValuesProp.phoneNbr === "") ||
        (mandatoryFieldsProp &&
            mandatoryFieldsProp.SupervisorNumber &&
            formValuesProp.supervisorNumber === "") ||
        (mandatoryFieldsProp &&
            mandatoryFieldsProp.FirstName &&
            formValuesProp.firstName.trim().length === 0) ||
        (mandatoryFieldsProp &&
            mandatoryFieldsProp.LastName &&
            formValuesProp.lastName.trim().length === 0) ||
        (mandatoryFieldsProp &&
            mandatoryFieldsProp.BusinessRelationship &&
            formValuesProp.businessRelationshipExternalId === "") ||
        (mandatoryFieldsProp &&
            mandatoryFieldsProp.ExternalPositionExternalId &&
            formValuesProp.externalPositionExternalId === "") ||
        (mandatoryFieldsProp &&
            mandatoryFieldsProp.Language &&
            formValuesProp.language === "") ||
        (mandatoryFieldsProp &&
            mandatoryFieldsProp.LegalEntityInternalId &&
            formValuesProp.legalEntityInternalId === "") ||
        (mandatoryFieldsProp &&
            mandatoryFieldsProp.OrgUnitExternalId &&
            formValuesProp.orgUnitExternalId === "") ||
        (mandatoryFieldsProp &&
            mandatoryFieldsProp.SubAuthorisationExternalId &&
            formValuesProp.subAuthorisationExternalId === "") ||
        (mandatoryFieldsProp && mandatoryFieldsProp.PhoneNbr)
    ) {
        return true;
    } else {
        return false;
    }
};