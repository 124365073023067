import { useState, useEffect } from 'react';
import * as signalR from '@microsoft/signalr';


const Progress = (percentage: any) => {
    const container: any = {
        height: 8,
        width: "400px",
        backgroundColor: "rgb(233 233 233)",
        borderRadius: 50,
    }

    const bar: any = {
        height: "100%",
        width: `${percentage.progress}%`,
        backgroundColor: "#005591",
        borderRadius: "inherit",
        position: 'relative'
    }

    return (
        
        <div style={ container }>
            <div style={bar}>
            </div>
        </div>
    );
};

export default Progress;