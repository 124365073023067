import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context";
import { Avatar, Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import elevateImg from "../../assets/elevate.png";
import lidapImg from "../../assets/lidap.png";
import linxImg from "../../assets/linxCard.png";
import { api } from "../../services";
import { generateToken } from "../../services/fetch-api/auth/authToken";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import LinearProgress from "@mui/material/LinearProgress";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import AppInsights from "./AppInsights";
import SuperAdminManagement from "./SuperAdminManagement";
import Dashboard from "./Dashboard";
import { TabPanelProps } from "../../types";

const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    p: 3,
    outline: "none",
};

const LinxCard = styled("div")(({ theme }) => ({
    width: "200px",
    height: "100px",
    boxShadow: "5px 5px 12px -6px rgba(0, 0, 0, 1)",
    padding: "20px",
    marginLeft: "20px",
    cursor: "pointer",
    borderRadius: "8px",
    backgroundImage: `url(${linxImg})`,
    backgroundPosition: "50%",
    backgroundSize: "cover",
    "&:hover": {
        opacity: "90%",
    },
    position: "relative",
}));

const ElevateCard = styled("div")(({ theme }) => ({
    width: "200px",
    height: "100px",
    backgroundColor: "white",
    boxShadow: "5px 5px 12px -6px rgba(0, 0, 0, 1)",
    padding: "20px",
    marginLeft: "20px",
    cursor: "pointer",
    borderRadius: "8px",
    backgroundImage: `url(${elevateImg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    "&:hover": {
        opacity: "90%",
        "& $OptionsButton": {
            background: "green",
        },
    },
    position: "relative",
    zIndex: "0",
}));

const OptionsButton = styled("div")(({ theme }) => ({
    width: "20px",
    height: "20px",
    boxShadow: "5px 5px 12px -6px rgba(0, 0, 0, 1)",
    cursor: "pointer",
    borderRadius: "50%",
    backgroundColor: `white`,
    position: "absolute",
    right: "-8px",
    top: "-8px",
    display: "none",
    zIndex: "7",
}));

const ExternalUserInfoMessage = styled("h4")(() => ({
    color: "#6e6e6e",
    marginLeft: "8px",
}));

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Home = () => {
    const { state, dispatch } = useContext(UserContext);
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const [entitlements, setEntitlements] = useState<any>([]);
    const [appInsights, setAppInsights] = useState<any>([]);
    const [appExceptions, setAppExceptions] = useState<any>([]);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [insightLoading, setInsightLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    function redirectIfOneBS(id: number) {
        if (state.user?.groups.length === 1) {
            dispatch({
                type: "SET_APPID",
                data: id,
            });
            navigate("/approval-list");
        }
    }

    if (state.user?.groups !== undefined) {
        if (state.user?.groups.length === 1) {
            if (
                state.user?.groups[0] ===
                "LEIP Elevate Business Administrators" ||
                state.user?.groups[0] ===
                "e0f7f12f-9f9b-41c0-9d97-f4657028d7dd" ||
                state.user?.groups[0] === "7b51cad9-9635-418e-834b-ad8d137d9099"
            ) {
                redirectIfOneBS(1);
            } else if (
                state.user?.groups[0] ===
                "LEIP LiDAP Business Administrators" ||
                state.user?.groups[0] ===
                "2a56c64c-8f8b-49d0-b3bc-418d35512740" ||
                state.user?.groups[0] === "7b51cad9-9635-418e-834b-ad8d137d9099"
            ) {
                redirectIfOneBS(3);
            }
        }
    }

    const navigateUserApp = (url: string, id: number) => {
        dispatch({
            type: "SET_APPID",
            data: id,
        });
        window.location.replace(url);
    };

    useEffect(() => {
        if (state.user) {
            api.entitlement
                .getUserEntitlement(
                    state.user,
                    generateToken(instance, accounts)
                )
                .then((res: any) => {
                    if (!res) {
                        return;
                    }
                    setEntitlements(res);
                });
        }

        if (state.user?.role === "SuperAdministrator" && state.appId === 1) {
            setInsightLoading(true);
            api.entitlement
                .getAppInsightTrace(
                    state.user,
                    1,
                    2000,
                    generateToken(instance, accounts)
                )
                .then((res: any) => {
                    if (!res) {
                        setInsightLoading(false);
                        return;
                    }
                    setAppInsights(res);
                    setInsightLoading(false);
                });
        }

        if (state.user?.role === "SuperAdministrator" && state.appId === 1) {
            api.entitlement
                .getAppExceptions(
                    state.user,
                    1,
                    2000,
                    generateToken(instance, accounts)
                )
                .then((res: any) => {
                    if (!res) {
                        setInsightLoading(false);
                        return;
                    }
                    setAppExceptions(res);
                    setInsightLoading(false);
                });
        }
    }, [state.user, state.appId, state.linxRoles]);


    return (
        <>
            {
                entitlements &&
                    entitlements.length > 0 &&
                    entitlements[0].name !== "Lidap" ? (
                    <>
                        <ExternalUserInfoMessage>
                            {t("label.welcome")} {state.user.firstName},{" "}
                            {t("label.youCanAccess")}
                        </ExternalUserInfoMessage>
                        <Grid
                            container
                            alignItems="center"
                            columnSpacing={2}
                            sx={{ marginBottom: 3 }}
                        >
                            <>
                                {entitlements.map(
                                    (data: any, index: number) => {
                                        if (data.id === 3) {
                                        } else if (data.id === 1) {
                                            return (
                                                <ElevateCard
                                                    onClick={(event) =>
                                                        event.currentTarget ==
                                                        event.target &&
                                                        navigateUserApp(
                                                            data.appUrl,
                                                            data.id
                                                        )
                                                    }
                                                    sx={{
                                                        ":hover .optionsButton":
                                                        {
                                                            display:
                                                                "block",
                                                        },
                                                    }}
                                                >
                                                    <OptionsButton
                                                        onClick={
                                                            handleOpenModal
                                                        }
                                                        className="optionsButton"
                                                    >
                                                        {" "}
                                                        <RemoveCircleIcon
                                                            sx={{
                                                                width: "20px",
                                                                height: "20px",
                                                                color: "red",
                                                            }}
                                                        />{" "}
                                                    </OptionsButton>
                                                </ElevateCard>
                                            );
                                        } else if (
                                            data.id ===
                                            Number(
                                                process.env.REACT_APP_LINX_ID
                                            )
                                        ) {
                                            return (
                                                <LinxCard
                                                    onClick={(event) =>
                                                        event.currentTarget ==
                                                        event.target &&
                                                        navigateUserApp(
                                                            data.appUrl,
                                                            data.id
                                                        )
                                                    }
                                                    sx={{
                                                        ":hover .optionsButton":
                                                        {
                                                            display:
                                                                "block",
                                                        },
                                                    }}
                                                >
                                                    <OptionsButton
                                                        onClick={
                                                            handleOpenModal
                                                        }
                                                        className="optionsButton"
                                                    >
                                                        {" "}
                                                        <RemoveCircleIcon
                                                            sx={{
                                                                width: "20px",
                                                                height: "20px",
                                                                color: "red",
                                                            }}
                                                        />{" "}
                                                    </OptionsButton>
                                                </LinxCard>
                                            );
                                        }
                                    }
                                )}
                            </>
                        </Grid>

                        <Modal open={openModal} onClose={handleCloseModal}>
                            <Box sx={modalStyle}>
                                <p style={{ textAlign: "center" }}>
                                    Are you sure you want to remove the
                                    entitlement?
                                </p>
                                <Stack
                                    sx={{ justifyContent: "center" }}
                                    direction="row"
                                    spacing={2}
                                >
                                    <Button
                                        variant="outlined"
                                        startIcon={<DeleteIcon />}
                                    >
                                        Remove
                                    </Button>
                                    <Button
                                        onClick={() => setOpenModal(false)}
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </Stack>
                            </Box>
                        </Modal>
                    </>
                ) :
                    state.user && state.user.firstName && state.user.role !== 'Administrator' && state.user.role !== 'SuperAdministrator' && entitlements && entitlements.length === 0 ? (
                        <p
                            style={{
                                color: "#1976d2",
                                fontSize: "18px",
                                fontWeight: "700",
                                textAlign: "center",
                                marginTop: "70px",
                            }}
                        >
                            No applications currently assigned
                        </p>
                    ) : (
                        state.user?.role === "SuperAdministrator" &&
                        state.appId !== Number(process.env.REACT_APP_LINX_ID) && (
                            <Box sx={{ width: "100%" }}>
                                <Box
                                    sx={{ borderBottom: 1, borderColor: "divider" }}
                                >
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                    >
                                        <Tab
                                            label={"Dashboard"}
                                            {...a11yProps(0)}
                                        />
                                        <Tab
                                            label={t("label.AppInsights")}
                                            {...a11yProps(1)}
                                        />
                                        <Tab
                                            label={t("label.AppExceptions")}
                                            {...a11yProps(2)}
                                        />
                                        <Tab
                                            label={t("label.superAdminManagement")}
                                            {...a11yProps(3)}
                                        />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    <Dashboard />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <AppInsights
                                        data={appInsights.appInsight}
                                        loading={insightLoading}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <AppInsights
                                        data={appExceptions.appInsight}
                                        loading={insightLoading}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <SuperAdminManagement />
                                </TabPanel>
                            </Box>
                        )
                    )
            }
        </>
    );
};

export default Home;
