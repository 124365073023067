import axios from "axios";
import { User } from "../../types";
import { toast } from 'react-toastify';
import i18n from 'i18next';

export const createApprovalListApi = (fetchApi: any) => {

    function getCSApprovalList(loggedUser: User, businessSegmentId: Number, token: any) {
        return fetchApi(`/api/EntitlementRequest/GetAllEntitlementRequestsCS/${businessSegmentId}`, loggedUser.language, 'GET', token);
    }

    function getLidapApprovalList(loggedUser: User, locationCode: Number, token: any) {
        return fetchApi(`/api/EntitlementRequest/GetAllEntitlementRequestsLidap/${locationCode}`, loggedUser.language, 'GET', token);
    }

    function getTrainingApprovalList(loggedUser: User, businessSegmentId: Number, locationCode: string, token: any) {
        return fetchApi(`/api/EntitlementRequest/GetAllEntitlementRequestsTraining/${businessSegmentId}/${locationCode}`, loggedUser.language, 'GET', token);
    }

    function getApprovalListDetails(loggedUser: User, id: number | any, token: any) {
        return fetchApi(`/api/EntitlementRequest/GetEntitlementRequestDetails/${id}`, loggedUser.language, 'GET', token);
    }

    function getOfflineApprovalList(loggedUser: User, businessSegmentId: Number, token: any) {
        return fetchApi(`/api/EntitlementRequest/GetAllEntitlementRequestsOffline/${businessSegmentId}`, loggedUser.language, 'GET', token);
    }

    async function rejectRequest(loggedUser: User, requestId: string | null, token: any) {

        return axios.put(`/api/EntitlementRequest/RejectEntitlementRequest/${requestId}`, null, {
            headers: {
                'Content-type': 'application/json',
                'Authorization': await token,
                'Accept-Language': loggedUser.language
            }
        }).then((res: any) => {
            toast.success('OK')
            return res
        }).catch((err) => {
            if (err.response.status) {
                const message = i18n.t('errorMessage.ErrorEntitlementList')
                toast.error(message)
            } else {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            }
        });
    }

    async function uploadFile(file: any, loggedUser: User, businessSegmentId: Number, appID: string, intelexRequired: string, token: any) {
        let uploadForm = new FormData();
        uploadForm.append('formFile', file);

        let userIdLidap = loggedUser.userIdLidap;

        // hack
        if (loggedUser.email === 'milan.stefanovic@tron-it.com' || loggedUser.email === 'milan.stefanovic2@linde.com') {
            userIdLidap = 'uk4111@linde.com';
        }
        let userId = appID === '3' || (appID == '1' && intelexRequired === 'Yes') ? userIdLidap : loggedUser.userIdCS;

        return axios.post(`/api/EntitlementRequest/PostBulkUpload/${businessSegmentId}/${appID}/${userId}`, uploadForm, {
            headers: {
                'Content-type': 'multipart/form-data',
                'Authorization': await token,
                'Accept-Language': loggedUser.language
            }
        }).then(() => {
            toast.success('OK');
        }).catch((err) => {
            if (err.response.status === 504) {
                const message = i18n.t('errorMessage.ErrorEntitlementList')
                toast.error(message)
            } else {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            }
        });
    }

    async function uploadFileOffline(file: any, loggedUser: User, businessSegmentId: Number, appID: string, intelexRequired: string, token: any, dispatch: any) {
        let uploadForm = new FormData();
        uploadForm.append('formFile', file);

        let userIdLidap = loggedUser.userIdLidap;

        // hack
        if (loggedUser.email === 'milan.stefanovic@tron-it.com' || loggedUser.email === 'milan.stefanovic2@linde.com') {
            userIdLidap = 'uk4111@linde.com';
        }
        let userId = appID === '3' || (appID == '1' && intelexRequired === 'Yes') ? userIdLidap : loggedUser.userIdCS;

        return axios.post(`/api/EntitlementRequest/PostBulkUploadOffline/${businessSegmentId}/${appID}/${userId}`, uploadForm, {
            headers: {
                'Content-type': 'multipart/form-data',
                'Authorization': await token,
                'Accept-Language': loggedUser.language
            },
        }).then((res) => {
            return res
        }).catch((err) => {
            if (err.response.status === 504) {
                const message = i18n.t('errorMessage.ErrorEntitlementList')
                toast.error(message)
            } else if (err.response.status === 400 || err.response.status === 404 || err.response.statue === 504) {
                dispatch({ type: 'SET_PROGRESS_ACTIVE', data: false });
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            } else {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            }
        });
    }

    function approveOfflineEntitlement(loggedUser: User, entitlements: number[], token: any) {
        return fetchApi(`/api/EntitlementRequest/ReprocessOfflineEntitlementRequest`, loggedUser.language, 'POST', token, entitlements);
    }

    //async function uploadFileLidap(file: any, loggedUser: User, appID: string, token: any) {
    //    let uploadForm = new FormData();
    //    uploadForm.append('formFile', file);        

    //    let userIdLidap = loggedUser.userIdLidap;

    //    if (loggedUser.email === 'milan.stefanovic@tron-it.com') {
    //        userIdLidap = 'uk4111@linde.com';
    //    }

    //    return axios.post(`/api/BulkUpload/PostBulkUploadLidap/${userIdLidap}/${appID}`, uploadForm, {
    //        headers: {
    //            'Content-type': 'multipart/form-data',
    //            'Authorization': await token,
    //            'Accept-Language': loggedUser.language
    //        }
    //    }).then(() => {
    //        toast.success('OK');
    //    }).catch((err) => {
    //        const json = JSON.parse(err.response.data);
    //        toast.error(json.Detail);
    //    });
    //}

    //async function uploadFileTraining(file: any, loggedUser: User, businessSegmentId: Number, appID: string, token: any) {
    //    let uploadForm = new FormData();
    //    uploadForm.append('formFile', file);

    //    let userIdLidap = loggedUser.userIdLidap;

    //    if (loggedUser.email === 'milan.stefanovic@tron-it.com') {
    //        userIdLidap = 'uk4111@linde.com';
    //    }
    //    return axios.post(`/api/BulkUpload/PostBulkUploadTraining/${businessSegmentId}/${userIdLidap}/${appID}`, uploadForm, {
    //        headers: {
    //            'Content-type': 'multipart/form-data',
    //            'Authorization': await token,
    //            'Accept-Language': loggedUser.language
    //        }
    //    }).then(() => {
    //        toast.success('OK');
    //    }).catch((err) => {
    //        const json = JSON.parse(err.response.data);
    //        toast.error(json.Detail);
    //    });
    //}

    return {
        getCSApprovalList,
        getLidapApprovalList,
        getTrainingApprovalList,
        getOfflineApprovalList,
        rejectRequest,
        uploadFile,
        uploadFileOffline,
        approveOfflineEntitlement,
        getApprovalListDetails
        //uploadFileLidap,
        //uploadFileTraining
    };
};