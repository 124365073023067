import { AccountInfo, IPublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';

export const generateToken = async (instance: IPublicClientApplication, accounts: AccountInfo[]) => {
  let token = '';
  const account: AccountInfo = accounts[0];

  const idToken: any = account.idTokenClaims;
    console.log(idToken);

  const emailHint: string = idToken && idToken.email ? idToken.email : idToken.mail;
  const phoneHint: string = idToken && idToken.phone ? idToken.phone : '';

  const silentRequestAdmin = {
    scopes: [`${process.env.REACT_APP_B2C_TENANT_URL}/${process.env.REACT_APP_PORTAL_APP_ID}/Full.Scope`],
    account: account,
    loginHint: emailHint ? emailHint : phoneHint,
    };


  await instance.acquireTokenSilent(silentRequestAdmin)
    .then((response) => {
      if (!response.accessToken || response.accessToken === "") {
        throw new InteractionRequiredAuthError;
      } else {
        token = response.accessToken;
      }
    })
    .catch((error) => {
      //if (process.env.NODE_ENV === 'development') {
        console.error('Error Getting Token', error);
      //}
    });
  
  return `Bearer ${token}`;
}