import { useContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { api } from "../../../services";
import { UserContext } from "../../../context";
import { generateToken } from "../../../services/fetch-api/auth/authToken";
import {
    Autocomplete,
    Box,
    Checkbox,
    CircularProgress,
    Drawer,
    FormControlLabel,
    Grid,
    Input,
    InputLabel,
    LinearProgress,
    Modal,
    Stack,
    TextField,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import {
    Error,
    DataGridStyled,
    DrawerBody,
    InputContainer,
    styleModal,
    styleDeleteModal,
} from "../../../styled";
import { defaultAddCompanyValues } from "../../../constants";
import { AddCompanyValues } from "../../../types";

const Companies = () => {
    const { t } = useTranslation();
    const { instance, accounts } = useMsal();
    const { state, dispatch } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedCompany, setSelectedCompany] = useState<any>([]);
    const [selectedCompanyObj, setSelectedCompanyObj] = useState<any>([]);
    const [items, setItems] = useState<any>([]);
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState<AddCompanyValues>(
        defaultAddCompanyValues
    );
    const [postCompanyLoading, setPostCompanyLoading] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [showDeleted, setShowDeleted] = useState(false);
    const [modifyCompanyValues, setModifyCompanyValues] = useState<any>();
    const [updateLoading, setUpdateLoading] = useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const columns: GridColDef[] = [
        {
            field: "companyName",
            headerName: "Name",
            width: 300,
        },
        {
            field: "status",
            headerName: "Status",
            width: 300,
        },
        {
            field: "modify",
            headerName: t("label.modify"),
            renderCell: () => (
                <Button variant="contained" size="small">
                    {t("label.modify")}
                </Button>
            ),
            width: 100,
        },
    ];

    useEffect(() => {
        setIsLoading(true);
        state.chosenLinxCountry &&
            api.linx
                .getLinxCompanies(
                    state.user,
                    state.chosenLinxCountry.value,
                    generateToken(instance, accounts)
                )
                .then((res: any) => {
                    setIsLoading(false);
                    setItems(res);
                });
        !state.chosenLinxCountry &&
            api.linx
                .getLinxCompanies(
                    state.user,
                    1,
                    generateToken(instance, accounts)
                )
                .then((res: any) => {
                    setIsLoading(false);
                    setItems(res);
                });
    }, [state.chosenLinxCountry]);

    const handleInputChange = (event: any) => {
        let errorMsg: any = document.querySelectorAll(".errorMsg");
        errorMsg.forEach((e: any) => {
            e.style.display = "none";
        });
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value.trim(),
        });
        setIsValid(/^[a-zA-Z0-9" _.,&()\[\]\-/]{1,32}$/.test(value));
    };

    const handleModifyInputChange = (event: any) => {
        let errorMsg: any = document.querySelectorAll(".errorMsg");
        errorMsg.forEach((e: any) => {
            e.style.display = "none";
        });
        const { name, value } = event.target;
        setModifyCompanyValues({
            ...modifyCompanyValues,
            [name]: value.trim() || "",
        });
        setIsValid(/^[a-zA-Z0-9" _.,&()\[\]\-/]{1,32}$/.test(value));
    };

    const handleAutocompleteChange = (value: string | null, name: string) => {
        let errorMsg: any = document.querySelectorAll(".errorMsg");
        errorMsg.forEach((e: any) => {
            e.style.display = "none";
        });
        setModifyCompanyValues({
            ...modifyCompanyValues,
            [name]: value || "",
        });
    };

    const handlePostCompany = () => {
        if (isValid && formValues.companyName !== "") {
            setPostCompanyLoading(true);
            state.chosenLinxCountry &&
                api.linx
                    .addLinxCompany(
                        state.user,
                        state.chosenLinxCountry.value,
                        formValues,
                        generateToken(instance, accounts)
                    )
                    .then((res: any) => {
                        setPostCompanyLoading(false);
                        setIsLoading(true);
                        state.chosenLinxCountry &&
                            api.linx
                                .getLinxCompanies(
                                    state.user,
                                    state.chosenLinxCountry.value,
                                    generateToken(instance, accounts)
                                )
                                .then((res: any) => {
                                    setIsLoading(false);
                                    setItems(res);
                                });
                        !state.chosenLinxCountry &&
                            api.linx
                                .getLinxCompanies(
                                    state.user,
                                    1,
                                    generateToken(instance, accounts)
                                )
                                .then((res: any) => {
                                    setIsLoading(false);
                                    setItems(res);
                                });
                        setOpen(false);
                        setFormValues(defaultAddCompanyValues);
                    });

            !state.chosenLinxCountry &&
                api.linx
                    .addLinxCompany(
                        state.user,
                        1,
                        formValues,
                        generateToken(instance, accounts)
                    )
                    .then((res: any) => {
                        setPostCompanyLoading(false);
                        setIsLoading(true);
                        state.chosenLinxCountry &&
                            api.linx
                                .getLinxCompanies(
                                    state.user,
                                    1,
                                    generateToken(instance, accounts)
                                )
                                .then((res: any) => {
                                    setIsLoading(false);
                                    setItems(res);
                                });
                        !state.chosenLinxCountry &&
                            api.linx
                                .getLinxCompanies(
                                    state.user,
                                    1,
                                    generateToken(instance, accounts)
                                )
                                .then((res: any) => {
                                    setIsLoading(false);
                                    setItems(res);
                                });
                        setOpen(false);
                        setFormValues(defaultAddCompanyValues);
                    });
        } else if (formValues.companyName === "") {
            let error: any = document.getElementById("CompanyError");
            error.style.display = "inline";
        } else if (formValues.companyName !== "" && !isValid) {
            let invalid: any = document.getElementById("CompanyInvalid");
            invalid.style.display = "inline";
        }
    };

    useEffect(() => {
        items.map((item: any) => {
            if (item.id === selectedCompany[0]) {
                setSelectedCompanyObj(item);
                setModifyCompanyValues({
                    companyName: item.companyName,
                    statusName: item.status,
                    businessId: state.chosenLinxCountry.value,
                    hasPlants: false,
                });
            }
        });
    }, [selectedCompany]);

    const handleUpdateCompany = () => {
        if (isValid && modifyCompanyValues.companyName !== "") {
            setUpdateLoading(true);
            api.linx
                .updateCompany(
                    state.user,
                    selectedCompanyObj.id,
                    modifyCompanyValues,
                    generateToken(instance, accounts)
                )
                .then(() => {
                    setUpdateLoading(false);
                    setOpenDeleteModal(false);
                    setModifyCompanyValues({
                        companyName: "",
                        statusName: "",
                        businessId: 0,
                        hasPlants: false,
                    });
                    setSelectedCompany([]);
                    state.chosenLinxCountry &&
                        api.linx
                            .getLinxCompanies(
                                state.user,
                                state.chosenLinxCountry.value,
                                generateToken(instance, accounts)
                            )
                            .then((res: any) => {
                                setIsLoading(false);
                                setItems(res);
                            });
                    !state.chosenLinxCountry &&
                        api.linx
                            .getLinxCompanies(
                                state.user,
                                1,
                                generateToken(instance, accounts)
                            )
                            .then((res: any) => {
                                setIsLoading(false);
                                setItems(res);
                            });
                });
        } else if (modifyCompanyValues.companyName === "") {
            let error: any = document.getElementById("CompanyError");
            error.style.display = "inline";
        } else if (modifyCompanyValues.companyName !== "" && !isValid) {
            let invalid: any = document.getElementById("CompanyInvalid");
            invalid.style.display = "inline";
        }
    };


    const rowsActiveAndDeleted = items;
    const rowsActive =
        items &&
        items.filter((data: any) => {
            if (data.status === "Active") return data;
        });

    const statusOptions: any = [
        { label: "Active", value: "Active" },
        { label: "Deleted", value: "Deleted" },
    ];

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(e) => {
                                setShowDeleted(!showDeleted);
                            }}
                        />
                    }
                    label={t("label.showDeletedCompanies")}
                />
                <Button variant="contained" onClick={handleOpen}>
                    Add
                </Button>
            </div>
            <Box sx={{ height: "calc(100vh - 160px)", width: "100%" }}>
                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <DataGridStyled
                        rows={showDeleted ? rowsActiveAndDeleted : rowsActive}
                        columns={columns}
                        pageSize={50}
                        onSelectionModelChange={(newSelectionModel: any) => {
                            setSelectedCompany(newSelectionModel);
                        }}
                        selectionModel={selectedCompany}
                        rowsPerPageOptions={[10]}
                    />
                )}
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Grid
                        container
                        columnSpacing={6}
                        sx={{ marginTop: "15px" }}
                    >
                        <Grid item xs={12} sx={{ marginBottom: "px" }}>
                            <InputContainer>
                                <InputLabel htmlFor="user-email">
                                    Company Name
                                    <span style={{ color: "red" }}> *</span>
                                    <Error
                                        className="errorMsg"
                                        id="CompanyError"
                                    >
                                        Required Field
                                    </Error>
                                    <Error
                                        className="errorMsg"
                                        id="CompanyInvalid"
                                    >
                                        Invalid input
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-email"
                                    name="companyName"
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>
                    {postCompanyLoading ? (
                        <LinearProgress />
                    ) : (
                        <Button variant="contained" onClick={handlePostCompany}>
                            {t("label.addCompany")}
                        </Button>
                    )}
                </Box>
            </Modal>

            <Modal
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleDeleteModal}>
                    <div>
                        <p style={{ textAlign: "center" }}>
                            LIVE Agents are assigned to this Parent Company.
                            Deleting the Company will automatically disassociate
                            ALL Agents from the Company.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            Are you sure you want to delete the company?
                        </p>
                        <div
                            style={{
                                display: "flex",
                                width: "50%",
                                justifyContent: "space-between",
                                margin: "auto",
                            }}
                        >
                            {updateLoading ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    variant="contained"
                                    onClick={handleUpdateCompany}
                                >
                                    Yes
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                onClick={() => setOpenDeleteModal(false)}
                            >
                                No
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Drawer
                anchor="right"
                open={!!selectedCompany.length}
                onClose={() => {
                    setSelectedCompany([]);
                    setModifyCompanyValues({ companyName: "", status: "" });
                }}
            >
                {selectedCompany && (
                    <DrawerBody>
                        <div style={{ width: "40vw" }}>
                            <p style={{ fontWeight: 600, fontSize: "20px" }}>
                                Modify Company
                            </p>
                            <InputContainer>
                                <InputLabel htmlFor="company-name">
                                    Company Name
                                    <span style={{ color: "red" }}> *</span>
                                    <Error
                                        className="errorMsg"
                                        id="CompanyError"
                                    >
                                        Required Field
                                    </Error>
                                    <Error
                                        className="errorMsg"
                                        id="CompanyInvalid"
                                    >
                                        Invalid input
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="company-name"
                                    name="companyName"
                                    defaultValue={
                                        modifyCompanyValues?.companyName
                                    }
                                    placeholder={
                                        modifyCompanyValues?.companyName
                                    }
                                    onChange={handleModifyInputChange}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputLabel htmlFor="company-name">
                                    Status
                                </InputLabel>

                                <Autocomplete
                                    disablePortal
                                    defaultValue={modifyCompanyValues?.status}
                                    id="combo-box-demo"
                                    options={statusOptions}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={
                                                modifyCompanyValues?.statusName
                                            }
                                        />
                                    )}
                                    onChange={(event: any, value: any) =>
                                        handleAutocompleteChange(
                                            value.value,
                                            "statusName"
                                        )
                                    }
                                />
                            </InputContainer>
                            <Stack direction="row" spacing={2}>
                                <Button
                                    variant="outlined"
                                    onClick={() => setSelectedCompany([])}
                                >
                                    Close
                                </Button>

                                {modifyCompanyValues &&
                                    modifyCompanyValues.statusName === "Deleted" ? (
                                    <Button
                                        variant="contained"
                                        onClick={() => setOpenDeleteModal(true)}
                                    >
                                        {t("formField.save")}
                                    </Button>
                                ) : updateLoading ? (
                                    <CircularProgress />
                                ) : (
                                    <Button
                                        variant="contained"
                                        onClick={() => handleUpdateCompany()}
                                    >
                                        {t("formField.save")}
                                    </Button>
                                )}
                            </Stack>
                        </div>
                    </DrawerBody>
                )}
            </Drawer>
        </>
    );
};

export default Companies;
