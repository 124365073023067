import OfflineUsers from "./OfflineUsers";
import OnlineUsers from "./OnlineUsers";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { TabPanelProps } from "../../types";
import { useState } from "react";
import { useTranslation } from "react-i18next";


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const ApprovalList = () => {

    const [value, setValue] = useState(0);
    const { t } = useTranslation();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={t('label.onlineUsers')} {...a11yProps(0)} />
                        <Tab label={t('label.offlineUsers')} {...a11yProps(1)} />
                    </Tabs>
                </Box> 
                <TabPanel value={value} index={0}>
                    <OnlineUsers />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <OfflineUsers />
                </TabPanel>
            </Box>
        </>
    );
}

export default ApprovalList;
