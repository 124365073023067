import React from "react";
import { getLocaleKey } from "../helpers";
import { IBusinessSegment, User } from "../types";

interface IContext {
    user: User | null;
    appId: number | null;
    appGuid: string | null;
    entitlementRequestId: number | null;
    lastAppId: number | null;
    language: string | null;
    progress: number;
    progressMessage: string;
    progressActive: boolean;
    orgUnit: any;
    approvalListLoading: boolean;
    linxRegionsAndCountries: any;
    linxRegions: any;
    linxCountries: any;
    chosenLinxCountry: any;
    chosenLinxCountryObject: any;
    linxRoles: string;
}

interface IContextAction {
    type:
        | "SET_USER"
        | "SET_APPID"
        | "SET_BUSINESS_SEGMENT"
        | "SET_LOCATION_CODE"
        | "SET_CHOSEN_BUSINESS_SEGMENT"
        | "SET_LANGUAGE"
        | "SET_PROGRESS"
        | "SET_PROGRESS_LABEL"
        | "SET_PROGRESS_ACTIVE"
        | "SET_SELECTED_ORGUNIT"
        | "SET_IS_APPROVALLIST_LOADING"
        | "SET_LINX_REGIONS_AND_COUNTRIES"
        | "SET_LINX_REGIONS"
        | "SET_LINX_COUNTRIES"
        | "SET_CHOSEN_LINX_COUNTRY"
        | "SET_CHOSEN_LINX_COUNTRY_OBJECT"
        | "SET_LINX_ROLES";
    data: User | number | IBusinessSegment | string | null;
}

const InitialState: IContext = {
    user: null,
    appId: Number(localStorage.getItem("appId")) || null,
    appGuid: localStorage.getItem("appGuid") || null,
    entitlementRequestId:
        Number(localStorage.getItem("entitlementRequestId")) || null,
    lastAppId: null,
    language: String(localStorage.getItem("i18nextLng")) || null,
    progress: 0,
    progressMessage: "",
    progressActive: false,
    orgUnit: null,
    approvalListLoading: false,
    linxRegionsAndCountries: [],
    linxRegions: [],
    linxCountries: [],
    chosenLinxCountry: null,
    chosenLinxCountryObject: null,
    linxRoles: "",
};

// @ts-ignore: Unreachable code error
export const UserContext = React.createContext<any>();

const UserReducer = (state: IContext, action: IContextAction) => {
    switch (action.type) {
        case "SET_USER":
            return { ...state, user: action.data };
        case "SET_APPID":
            return { ...state, appId: action.data };
        case "SET_BUSINESS_SEGMENT":
            return { ...state, businessSegment: action.data };
        case "SET_CHOSEN_BUSINESS_SEGMENT":
            return { ...state, chosenBusinessSegment: action.data };
        case "SET_LOCATION_CODE":
            return { ...state, locationCode: action.data };
        case "SET_LANGUAGE":
            return { ...state, language: action.data };
        case "SET_PROGRESS":
            return { ...state, progress: action.data };
        case "SET_PROGRESS_LABEL":
            return { ...state, progressMessage: action.data };
        case "SET_PROGRESS_ACTIVE":
            return { ...state, progressActive: action.data };
        case "SET_SELECTED_ORGUNIT":
            return { ...state, orgUnit: action.data };
        case "SET_IS_APPROVALLIST_LOADING":
            return { ...state, approvalListLoading: action.data };
        case "SET_LINX_REGIONS_AND_COUNTRIES":
            return { ...state, linxRegionsAndCountries: action.data };
        case "SET_LINX_REGIONS":
            return { ...state, linxRegions: action.data };
        case "SET_LINX_COUNTRIES":
            return { ...state, linxCountries: action.data };
        case "SET_CHOSEN_LINX_COUNTRY":
            return { ...state, chosenLinxCountry: action.data };
        case "SET_CHOSEN_LINX_COUNTRY_OBJECT":
            return { ...state, chosenLinxCountryObject: action.data };
        case "SET_LINX_ROLES":
            return { ...state, linxRoles: action.data };
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
};

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
    // @ts-ignore: Unreachable code error
    const [state, dispatch] = React.useReducer(UserReducer, InitialState);
    const value = { state, dispatch };
    return (
        <UserContext.Provider value={value}>{children}</UserContext.Provider>
    );
};
