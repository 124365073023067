import React, { useContext, useEffect, useState, useRef } from "react";
import { UserContext } from "../../context";
import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Input,
    InputLabel,
    CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { api } from "../../services";
import { generateToken } from "../../services/fetch-api/auth/authToken";
import { useMsal } from "@azure/msal-react";
import Select from "react-select";
import { IEditBS } from "../../types";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { timezones } from "../../assets/timezones";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import i18n from "../../i18n";
import { InputContainer } from "../../styled";

const defaultValues = {
    internalId: 0,
    externalId: "",
    countryCode: "",
    defaultTimezone: "",
    isActive: false,
    IntelexRequired: false,
    configArray: [],
    mandatoryConfig: [],
    separator: "",
};

const EditBusinessSegment = ({ data, handleClose }: any) => {
    const { state, dispatch } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const [formValues, setFormValues] = useState<IEditBS>(defaultValues);
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set<number>());
    const [allBusinessSegments, setAllBusinessSegments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [timezonesArray, setTimezonesArray] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();
    const steps = [
        t("label.EnterInfo"),
        t("label.SelectFields"),
        t("label.SelectMandatory"),
    ];

    const [chosenFieldsArray, setChosenFieldsArray] = useState<string[]>([]);
    const [mandatoryFields, setMandatoryFields] = useState<any[]>([]);
    const [allLanguageCodes, setAllLanguageCodes] = useState([]);

    const options =
        state.businessSegment !== undefined &&
        state.businessSegment.businessSegments.map((data: any) => {
            return {
                value: data.internalId,
                label: data.externalId + " - " + data.name,
            };
        });
    useEffect(() => {
        setAllBusinessSegments(options);
    }, [state.user, state.businessSegment]);

    useEffect(() => {
        state.user &&
            state.user?.firstName &&
            state.user?.lastName &&
            api.userDetails
                .getSupportedLanguages(state.appId)
                .then((res: any) => {
                    if (!res) return;
                    setAllLanguageCodes(
                        res.map((data: any) => {
                            return { value: data.value, label: data.value };
                        })
                    );
                    setTimezonesArray(
                        timezones.map((data: any) => {
                            return { value: data.text, label: data.text };
                        })
                    );
                });
    }, [state.user]);

    const fieldsOptionsIntelex = [
        "SubAuthorisationID",
        "CountryCode",
        "UserSupportInfoHierarchy1",
        "UserSupportInfoHierarchy2",
        "UserSupportInfoHierarchy3",
        "FirstName",
        "LastName",
        "Email",
        "PhoneNbr",
        "SupportInfo1",
        "SupportInfo2",
        "SupportInfo3",
        "LindeManagerCPID",
        "BusinessRelationship",
        "OrgUnitLevel1",
        "OrgUnitLevel2",
        "OrgUnitLevel3",
        "OrgUnitLevel4",
        "OrgUnitLevel5",
        "ExternalPosition",
        "Location",
        "CornerstoneData1",
        "CornerstoneData2",
        "CornerstoneData3",
        "CornerstoneData4",
        "Locale",
        "LocationCode",
        "SupervisorNumber",
        "PositionTitle",
        "Company",
    ];

    const fieldsOptions = [
        "SubAuthorisationID",
        "CountryCode",
        "UserSupportInfoHierarchy1",
        "UserSupportInfoHierarchy2",
        "UserSupportInfoHierarchy3",
        "FirstName",
        "LastName",
        "Email",
        "PhoneNbr",
        "SupportInfo1",
        "SupportInfo2",
        "SupportInfo3",
        "LindeManagerCPID",
        "BusinessRelationship",
        "OrgUnitLevel1",
        "OrgUnitLevel2",
        "OrgUnitLevel3",
        "OrgUnitLevel4",
        "OrgUnitLevel5",
        "ExternalPosition",
        "Location",
        "CornerstoneData1",
        "CornerstoneData2",
        "CornerstoneData3",
        "CornerstoneData4",
        "Locale",
    ];

    const [fieldsArray, setFieldsArray] = useState<string[]>([]);
    const [chosenFields, setChosenFields] = useState(formValues.configArray);
    const [fieldsArrayWithNoChosen, setFieldsArrayWithNoChosen] = useState<
        string[]
    >([]);

    useEffect(() => {
        if (data !== undefined) {
            setFormValues({
                internalId: data.internalId,
                externalId: data.externalId,
                countryCode: data.countryCode,
                defaultTimezone: data.defaultTimeZone,
                isActive: data.isActive,
                IntelexRequired: data.intelexRequired === "Yes" ? true : false,
                separator:
                    data.appConfiguration[1] !== ""
                        ? data.appConfiguration[1].parameterValue
                        : "",
                configArray:
                    data.appConfiguration[0].parameterValues !== undefined
                        ? data.appConfiguration[0].parameterValue.split(",")
                        : {},
                mandatoryConfig:
                    data.appConfiguration[2].parameterValues !== undefined
                        ? data.appConfiguration[2].parameterValue.split(",")
                        : {},
            });

            setFieldsArray(
                data.intelexRequired === "Yes"
                    ? fieldsOptionsIntelex
                    : fieldsOptions
            );

            if (data.appConfiguration[3] || data.appConfiguration[5]) {
                data.appConfiguration[0].parameterValue &&
                    setChosenFields(
                        data.intelexRequired === "Yes"
                            ? data.appConfiguration[3].parameterValue.split(",")
                            : data.appConfiguration[0].parameterValue.split(",")
                    );
                data.appConfiguration[2].parameterValue &&
                    setMandatoryFields(
                        data.intelexRequired === "Yes"
                            ? data.appConfiguration[5].parameterValue.split(",")
                            : data.appConfiguration[2].parameterValue.split(",")
                    );
            } else {
                data.appConfiguration[0].parameterValue &&
                    setChosenFields(
                        data.appConfiguration[0].parameterValue.split(",")
                    );
                data.appConfiguration[2].parameterValue &&
                    setMandatoryFields(
                        data.appConfiguration[2].parameterValue.split(",")
                    );
            }

            /*data.appConfiguration[0].parameterValue && setLoading(false)*/
            data.externalId !== "" ? setLoading(false) : setLoading(true);
        }
    }, [data]);

    useEffect(() => {
        const y = fieldsArray.filter((x) => !chosenFields?.includes(x));
        setFieldsArrayWithNoChosen(y);
    }, [chosenFields]);

    const handleSelectionChange = (event: any) => {
        setChosenFields((oldArray: any) => [...oldArray, event.target.value]);
        setFieldsArrayWithNoChosen((prevState: any) =>
            prevState.map((data: any) => {
                return data !== event.target.value && data;
            })
        );
    };

    const removeItem = (event: any) => {
        const id = event.currentTarget.id;
        setChosenFields((prevState: any) =>
            prevState
                .map((data: any) => {
                    return data !== id && data;
                })
                .filter(Boolean)
        );

        setMandatoryFields((prevState: any) =>
            prevState
                .map((data: any) => {
                    return data !== id && data;
                })
                .filter(Boolean)
        );

        setFieldsArrayWithNoChosen((prevState) => [...prevState, id]);
    };

    const handleSelectAll = () => {
        setChosenFieldsArray([]);
        setChosenFields(
            data.intelexRequired === "Yes"
                ? fieldsOptionsIntelex
                : fieldsOptions
        );
    };

    const handleClearAll = () => {
        setChosenFieldsArray(
            data.intelexRequired === "Yes"
                ? fieldsOptionsIntelex
                : fieldsOptions
        );
        setChosenFields([]);
    };
    useEffect(() => {
        setFormValues((prevState) => {
            return { ...prevState, configArray: chosenFields };
        });
    }, [chosenFields, fieldsArrayWithNoChosen]);

    const handleSelectMandatory = (event: any) => {
        if (event.target.checked === true) {
            setMandatoryFields((oldArray) => [...oldArray, event.target.value]);
        } else if (event.target.checked === false) {
            setMandatoryFields((prevState: any) =>
                prevState
                    .map((data: any) => {
                        return data !== event.target.value && data;
                    })
                    .filter(Boolean)
            );
        }
    };

    useEffect(() => {
        setFormValues((prevState) => {
            return { ...prevState, mandatoryConfig: mandatoryFields };
        });
    }, [mandatoryFields]);

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleInputChange = (event: any) => {
        let errorMsg: any = document.querySelectorAll(".errorMsg");
        errorMsg.forEach((e: any) => {
            e.style.display = "none";
        });
        const { name, value } = event.target;
        if (event.target.type === "number") {
            setFormValues({
                ...formValues,
                [name]: parseInt(value),
            });
        } else {
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
    };

    const editBusinessSegment = () => {
        setIsLoading(true);
        api.entitlement
            .updateBusinessSegment(
                state.user,
                formValues,
                generateToken(instance, accounts)
            )
            .then((res: any) => {
                if (!res) {
                    setIsLoading(false);
                    return;
                } else {
                    handleClose(false);
                    const message = i18n.t("successMessage.bsEdited");
                    toast.success(message);
                    setIsLoading(false);
                }
            });
    };

    return (
        <div>
            {
                <>
                    <Box
                        sx={{
                            width: "100%",
                            marginTop: "30px",
                            height: "calc(100vh - 300px)",
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                        }}
                    >
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const stepProps: { completed?: boolean } = {};
                                const labelProps: {
                                    optional?: React.ReactNode;
                                } = {};
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>
                                            {label}
                                        </StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        pt: 2,
                                    }}
                                >
                                    <Box sx={{ flex: "1 1 auto" }} />
                                    <Button onClick={handleReset}>Reset</Button>
                                </Box>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {activeStep === 0 ? (
                                    !loading ? (
                                        <Box
                                            sx={{
                                                width: "calc(100vh-30px)",
                                                margin: "20px",
                                            }}
                                        >
                                            <Grid
                                                container
                                                columnSpacing={6}
                                                sx={{ marginTop: "15px" }}
                                            >
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sx={{ marginBottom: "px" }}
                                                >
                                                    <InputContainer>
                                                        <InputLabel htmlFor="internal-id">
                                                            {"Internal ID"}
                                                        </InputLabel>
                                                        <Input
                                                            type="number"
                                                            fullWidth
                                                            id="internal-id"
                                                            name="internalId"
                                                            value={
                                                                formValues.internalId
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                    </InputContainer>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <InputContainer>
                                                        <InputLabel htmlFor="external-id">
                                                            {t(
                                                                "label.ExternalId"
                                                            )}
                                                        </InputLabel>
                                                        <Input
                                                            fullWidth
                                                            id="external-id"
                                                            name="externalId"
                                                            value={
                                                                formValues.externalId
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                    </InputContainer>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                columnSpacing={6}
                                                sx={{ marginTop: "15px" }}
                                            >
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sx={{ marginBottom: "px" }}
                                                >
                                                    <InputContainer>
                                                        <InputLabel htmlFor="country-code">
                                                            {t("label.locale")}
                                                        </InputLabel>
                                                        <Select
                                                            placeholder={
                                                                formValues.countryCode
                                                            }
                                                            id="country-code"
                                                            name="countryCode"
                                                            options={
                                                                allLanguageCodes
                                                            }
                                                            value={
                                                                formValues.countryCode
                                                            }
                                                            onChange={(
                                                                event: any
                                                            ) => {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    countryCode:
                                                                        event.value,
                                                                });
                                                            }}
                                                        />
                                                    </InputContainer>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sx={{ marginBottom: "px" }}
                                                >
                                                    <InputContainer>
                                                        <InputLabel htmlFor="default-timezone">
                                                            {t(
                                                                "label.TimeZone"
                                                            )}
                                                        </InputLabel>
                                                        <Select
                                                            placeholder={
                                                                formValues.defaultTimezone
                                                            }
                                                            id="default-timezone"
                                                            name="defaultTimezone"
                                                            options={
                                                                timezonesArray
                                                            }
                                                            value={
                                                                formValues.defaultTimezone
                                                            }
                                                            onChange={(
                                                                event: any
                                                            ) => {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    defaultTimezone:
                                                                        event.value,
                                                                });
                                                            }}
                                                        />
                                                    </InputContainer>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                columnSpacing={6}
                                                sx={{ marginTop: "15px" }}
                                            >
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sx={{ marginBottom: "px" }}
                                                >
                                                    <InputContainer>
                                                        <InputLabel htmlFor="separator">
                                                            {t(
                                                                "label.Separator"
                                                            )}
                                                        </InputLabel>
                                                        <Input
                                                            fullWidth
                                                            id="separator"
                                                            name="separator"
                                                            value={
                                                                formValues.separator
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                    </InputContainer>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                columnSpacing={6}
                                                sx={{ marginTop: "15px" }}
                                            >
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sx={{ marginBottom: "px" }}
                                                >
                                                    <FormControlLabel
                                                        label={t(
                                                            "label.Active"
                                                        )}
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    formValues.isActive
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            isActive:
                                                                                event
                                                                                    .target
                                                                                    .checked,
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sx={{ marginBottom: "px" }}
                                                >
                                                    <FormControlLabel
                                                        label={t(
                                                            "label.IntelexRequired"
                                                        )}
                                                        control={
                                                            <Checkbox
                                                                disabled
                                                                checked={
                                                                    formValues.IntelexRequired
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setFormValues(
                                                                        {
                                                                            ...formValues,
                                                                            IntelexRequired:
                                                                                event
                                                                                    .target
                                                                                    .checked,
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    ) : (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <CircularProgress size={80} />
                                        </div>
                                    )
                                ) : activeStep === 1 ? (
                                    <Grid
                                        container
                                        columnSpacing={2}
                                        sx={{ marginTop: "30px" }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                ml: 3,
                                                border: "1px solid hsl(0, 0%, 80%)",
                                                width: "45%",
                                                padding: "15px",
                                                borderRadius: "12px",
                                                height: "48vh",
                                                overflowY: "scroll",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <p>{t("label.ChooseFileds")}</p>
                                                <Button
                                                    onClick={handleSelectAll}
                                                >
                                                    {t("label.SelectAll")}
                                                </Button>
                                            </div>
                                            {fieldsArrayWithNoChosen.map(
                                                (data: any) => {
                                                    return (
                                                        data !== false && (
                                                            <FormControlLabel
                                                                label={data}
                                                                value={data}
                                                                control={
                                                                    <Checkbox />
                                                                }
                                                                onChange={
                                                                    handleSelectionChange
                                                                }
                                                            />
                                                        )
                                                    );
                                                }
                                            )}
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "block",
                                                flexDirection: "column",
                                                ml: 3,
                                                border: "1px solid hsl(0, 0%, 80%)",
                                                width: "45%",
                                                padding: "15px",
                                                borderRadius: "12px",
                                                height: "48vh",
                                                overflowY: "scroll",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <p>Chosen fields</p>
                                                <Button
                                                    onClick={handleClearAll}
                                                >
                                                    {t("label.ClearAll")}
                                                </Button>
                                            </div>
                                            {chosenFields?.map((data: any) => {
                                                return (
                                                    data !== false && (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "space-between",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <p
                                                                style={{
                                                                    margin: 0,
                                                                }}
                                                                id={data}
                                                            >
                                                                {data}
                                                            </p>
                                                            <IconButton
                                                                onClick={
                                                                    removeItem
                                                                }
                                                                id={data}
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </div>
                                                    )
                                                );
                                            })}
                                        </Box>
                                    </Grid>
                                ) : (
                                    <Grid
                                        container
                                        columnSpacing={2}
                                        sx={{ marginTop: "30px" }}
                                    >
                                        <Box
                                            sx={{
                                                display: "block",
                                                flexDirection: "column",
                                                ml: 3,
                                                border: "1px solid hsl(0, 0%, 80%)",
                                                width: "100%",
                                                padding: "15px",
                                                borderRadius: "12px",
                                                height: "48vh",
                                                overflowY: "scroll",
                                            }}
                                        >
                                            <p>{t("label.ChooseMandatory")}</p>

                                            {chosenFields?.map(
                                                (data: any, index: any) => {
                                                    return (
                                                        data !== false && (
                                                            <FormControlLabel
                                                                label={data}
                                                                value={data}
                                                                control={
                                                                    <Checkbox
                                                                        checked={
                                                                            mandatoryFields.includes(
                                                                                data
                                                                            )
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                }
                                                                onChange={
                                                                    handleSelectMandatory
                                                                }
                                                            />
                                                        )
                                                    );
                                                }
                                            )}
                                        </Box>
                                    </Grid>
                                )}

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        pt: 2,
                                    }}
                                >
                                    <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        {t("label.Back")}
                                    </Button>
                                    <Box sx={{ flex: "1 1 auto" }} />

                                    {activeStep === steps.length - 1 ? (
                                        isLoading ? (
                                            <CircularProgress />
                                        ) : (
                                            <Button
                                                onClick={editBusinessSegment}
                                            >
                                                {t("label.Finish")}
                                            </Button>
                                        )
                                    ) : (
                                        <Button onClick={handleNext}>
                                            {t("label.Next")}
                                        </Button>
                                    )}
                                </Box>
                            </React.Fragment>
                        )}
                    </Box>
                </>
            }
        </div>
    );
};

export default EditBusinessSegment;
