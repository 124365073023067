import { IconButton, Input, InputLabel } from "@mui/material";
import { useState, useContext } from "react";
import { UserContext } from "../../context";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";

function TreeSearch({ data, searchQuery, setSearchQuery, selectedItem, setSelectedItem }: any) {
    const { state, dispatch } = useContext(UserContext);
    const { t } = useTranslation();

    const handleSearchInputChange = (event: any) => {
        setSearchQuery(event.target.value);
    };

    function flatten(node: any, arr: any, query: string) {
        arr.push(node.data);
        if (node.children) {
            node.children.forEach((child: any) => {
                flatten(child, arr, searchQuery);
            })
        }
    }

    let flatData: any = [];
    data && flatten(data, flatData, searchQuery);

    function handleClick(item: any) {
        setSelectedItem(item)
        dispatch({ type: 'SET_SELECTED_ORGUNIT', data: item });
        setSearchQuery('')
    }

    return (
        <div>
            <div>
                <InputLabel htmlFor="user-last-name">
                    {t('label.orgUnits')}
                </InputLabel>
                <div style={ { display: 'flex' } }>
                    <Input
                        fullWidth
                        placeholder={state.orgUnit && state.orgUnit.data ? state.orgUnit.data.name : state.orgUnit ? state.orgUnit.name :  t('label.searchOrChoose')}
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                    />
                    <IconButton onClick={() => dispatch({ type: 'SET_SELECTED_ORGUNIT', data: null })}>
                        <ClearIcon />
                    </IconButton>
                 </div>
            </div>
            {searchQuery !== '' &&
              flatData && flatData.map((data: any) => {
                  if (data !== undefined && data.name.toLowerCase().includes(searchQuery.toLowerCase())) {
                      return (
                          data && <div onClick={() => handleClick(data)}><p style={{ padding: '2px 6px', marginLeft: 2, marginTop: 1, marginBottom: 1, cursor: 'pointer', backgroundColor: state.orgUnit && data.internalId === state.orgUnit.internalId ? '#dfeeff' : 'white' }}>{data.name}</p></div> 
                    )}
                })
            }
        </div>
    );
}

export default TreeSearch;
